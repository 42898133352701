import { configureStore } from '@reduxjs/toolkit'
import showSideBarReducer from '../features/showSideBar/showSideBar'
import allPagesReducer from '../features/addPages/addPages'
import createFromDaraReducer from '../features/formData/formData'
import showImagePopUpReducer from '../features/ShowImagePopUp/showImagePopUp'
import menusReducer from '../features/addMenu'
import logicBuilderReducer from '../features/logicBuilder/logicBuilder'
import rightImagesReducer from '../features/rightImages/rightImages'
import imagesDetailsReducer from '../features/ImageDetails/imageDetails'
import contentEditorReducer from '../features/contentEditor/contentEditor'
import widgetTypeFilterReducer from '../features/widgetFilter/widgetFilter'
import authPermissionReducer from '../features/authPermissions/authPermissions'
import apgeIdReducer from '../features/pageId/pageId'
import popupReducer from '../features/popupSlice';

export const store = configureStore({
  reducer: {
    showSideBar: showSideBarReducer,
    allPages: allPagesReducer,
    createFormData: createFromDaraReducer,
    showImagePopUp: showImagePopUpReducer,
    menus: menusReducer,
    logicBuilder: logicBuilderReducer,
    rightImages: rightImagesReducer,
    imageDetails: imagesDetailsReducer,
    contentEditor: contentEditorReducer,
    widgetTypeFilter: widgetTypeFilterReducer,
    authPermission: authPermissionReducer,
    pageId: apgeIdReducer,
    popupControl: popupReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch