import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Page } from '../addPages/addPages';

export interface PageId {
    pageId: string,
    rollbackPage: Page | null;
    rollbackLoading: boolean;
}

const initialState: PageId = {
    pageId: '',
    rollbackPage: null,
    rollbackLoading: false,
}

export const pageIdSlice = createSlice({
    name: 'pageId',
    initialState,
    reducers: {
        getPageId: (state, action: PayloadAction<string>) => {
            state.pageId = action.payload
        },
        setRollbackPage: (state, action: PayloadAction<Page | null>) => {
            state.rollbackPage = action.payload

            if (action.payload !== null) {
                state.rollbackLoading = true
            } else {
                state.rollbackLoading = false
            }
        }
    }
})

export const {
    getPageId,
    setRollbackPage
} = pageIdSlice.actions

export default pageIdSlice.reducer