import React from 'react'
import MenuIcon from '../../icons/MenuIcon'
import UserIcon from '../../icons/UserIcon'
import './header.css'

import type { RootState } from '../../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { toggleSideBar } from '../../../features/showSideBar/showSideBar'
import { removeToken } from '../../../App'
import LogOutIcon from '../../icons/Logout'

const Header:React.FC = () => {
  const showSideBar = useSelector((state: RootState) => state.showSideBar.value)
  const dispatch = useDispatch()
  // const handleLogout = () => {
  //   console.log('logout')
  //   removeToken()
  //   window.location.reload();
  // }
  return (
    <div className={`heade__container ${showSideBar ? 'text-gray-700' : 'text-indigo-400'}`}>
      <div className="header__row">
        <div className="header__col--one">
          <div className='header__col--icon' onClick={()=> dispatch(toggleSideBar())}>
            <MenuIcon />
          </div>
        </div>
        <div className="header__col--two">
          <a href={process.env.REACT_APP_LOGIN_REDIRECT} className='header__col--icon'>
            <LogOutIcon/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header