import React, { FC } from 'react'
import './populerProduct.css'

const PopulerProduct:FC = () => {
  return (
    <div className='populerProduct__container'>
        <div className="populerProduct__wrapper">
            <img src="/img/widgets/popular-product.png" alt=''/>
        </div>
    </div>
  )
}

export default PopulerProduct