import { CSSProperties, useEffect, useState } from 'react';
import { setGlobalPopupElement } from '../../features/popupSlice';
import CloseIcon from '../icons/CloseIcon';
// import classes from './ChangeLogPopup.module.css'

import DataTable from 'react-data-table-component';
import { getPageChangeLogById, getPageChangeLogs } from '../../utils/requests';
import { ChangeLogDataType, ChangeLogItemType } from '../../types/change-log';

import {
	adminUserProfileUrl,
} from '../../utils/constants';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { setRollbackPage } from '../../features/pageId/pageId';

interface Props {
	pageId: string;
	className?: string;
	style?: CSSProperties;
}

const columns: any = [
	{
		name: 'Log',
		wrap: true,
		selector: (row: ChangeLogItemType) => (
			<p>{row.change_log}</p>
		),
	},
	{
		name: 'User',
		wrap: 1,
		selector: (row: ChangeLogItemType) => (
			<a
				href={adminUserProfileUrl + row.user?.id}
				target={'_blank'}
				rel={'noreferrer'}
				className={'text-blue-500 underline'}
			>{row.user?.first_name} {row.user?.last_name}</a>
		),
	},
	{
		name: 'Updated At',
		wrap: true,
		selector: (row: ChangeLogItemType) => (
			<p>{new Date(row.createdAt || '').toLocaleString()}</p>
		),
	},
	{
		name: 'Status',
		wrap: true,
		selector: (row: ChangeLogItemType) => (
			<p>{row.page_status?.toUpperCase()}</p>
		),
	},
	{
		name: 'Action',
		wrap: true,
		selector: (row: ChangeLogItemType) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const dispatch = useDispatch();

			return (
				<button
					className={'bg-blue-500 text-white px-2 py-1 rounded'}
					onClick={() => {
						// rollback to this version
						getPageChangeLogById(row._id || '').then((data) => {
							dispatch(setRollbackPage(data.page || null));
						});
					}}
				>Rollback</button>
			)
		},
	}
];


// list of page chagne log with date, time, user and page object
// list of widget change log with date, time, user and widget object
const logs = [
	{
		date: '2021-01-01',
		time: '12:00',
		user: 'user',
		page: {
			id: 'page-id',
			name: 'page-name',
			createdAt: '2021-01-01',
			updatedAt: '2021-01-01',
			deletedAt: '2021-01-01',
			deleted: false,
		}
	},
	{
		date: '2021-01-01',
		time: '12:00',
		user: 'user 2',
		page: {
			id: 'page-id',
			name: 'page-name',
			createdAt: '2021-01-01',
			updatedAt: '2021-01-01',
			deletedAt: '2021-01-01',
			deleted: false,
		}
	},
]

const PER_PAGE = 8;

const ChangeLogPopup = ({
	pageId,
	className,
	style,
}: Props) => {
	const dispatch = useDispatch();
	const [logData, setLogData] = useState<ChangeLogDataType>();

	const [activePage, setActivePage] = useState(1);

	const totalPage = Math.ceil((logData?.totalCount || 0) / (PER_PAGE || 1));

	// const classNames = [classes.ChangeLogPopup, className];

	// const columnItems: DataType[] = voucherItems.map((item) => ({
	// 	code: item.code,
	// 	name: item.name,
	// 	amount: item.discount_value || 0,
	// 	type: (!!item.discount_type && item.discount_type === 'fixed') ? 'fixed' : 'percent',
	// 	status: !!item.status && (item.status === 'active' || item.status === 'submit') ? 'active' : 'expired',
	// 	validFrom: (new Date(item.start_date || '')).toLocaleDateString(),
	// 	validUntil: (new Date(item.end_date || '')).toLocaleDateString(),
	// 	onRemove: () => removeHandler(item),
	// }));

	useEffect(() => {
		// fetch data from api
		getPageChangeLogs(pageId, activePage, PER_PAGE).then((data) => {
			setLogData(data);
		});

	}, [pageId, activePage]);

	const closePopup = () => {
		dispatch(setGlobalPopupElement(null));
	}

	const handlePageClick = (data:any) => {
		const _currentPage: number = data.selected + 1;
		setActivePage(_currentPage);
	  }

	return (
		<div className='fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black/20' style={style}>
			<div onClick={closePopup} className="absolute top-0 left-0 w-full h-full -z-10"></div>
			{/* list of change logs */}
			<div className='w-[80vw] h-[90vh] bg-white'>
				<div className='w-full h-16 bg-gray-200 flex items-center justify-between px-4'>
					<div className='text-gray-500 font-bold'>Change Log</div>
					<button onClick={closePopup} className='text-gray-500 font-bold'>
						<CloseIcon />
					</button>
				</div>

				<DataTable
					columns={columns}
					data={logData?.data || []}
					subHeaderWrap={true}
				/>

				{/* <div className='w-full h-full overflow-y-auto'>
					{
						logs.map((log, index) => {
							return (
								<div key={index} className='w-full h-16 flex items-center justify-between px-4'>
									<div className='text-gray-500 font-bold'>{log.date}</div>
									<div className='text-gray-500 font-bold'>{log.time}</div>
									<div className='text-gray-500 font-bold'>{log.user}</div>
									<div className='text-gray-500 font-bold'>
										<CloseIcon />
									</div>
								</div>
							)
						})
					}
				</div> */}

				{totalPage > 1 && (
					<div>
						<ReactPaginate 
							previousLabel={"<"}
							nextLabel={">"}
							breakLabel={"..."}
							pageCount={totalPage || 0}   
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}   
							onPageChange={handlePageClick}
							containerClassName={'flex items-center justify-center'}
							pageClassName={''}
							pageLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
							activeClassName={'bg-blue-500 text-white rounded-full shadow-lg'}
							previousClassName={''}
							previousLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
							breakClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
							nextClassName={''}
							nextLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default ChangeLogPopup