import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ShowContenteEditor {
    value: boolean,
    content: string,
    dataSave: boolean
}

const initialState: ShowContenteEditor = {
    value: false,
    content: '',
    dataSave: false
}

export const showContentEditorSlice = createSlice({
    name: 'showContentEditor',
    initialState,
    reducers: {
        openContentEditor: (state) => {
            state.value = true
        },
        closeContentEditor: (state) => {
            state.value = false
        },
        handleContentEditorData: (state, action: PayloadAction<string>) => {
            state.content = action.payload
        },
        handleDataSave: (state, action: PayloadAction<boolean>) => {
            state.dataSave = action.payload
        } 
    }
})

export const { openContentEditor, closeContentEditor, handleContentEditorData, handleDataSave} = showContentEditorSlice.actions

export default showContentEditorSlice.reducer