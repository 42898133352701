import React from 'react'

const HighlightedShop:React.FC = () => {
  return (
    <div className='campaign__container'>
        <div className="campaign__wrapper">
            <img src="/img/widgets/highlighted-shop.png" alt=''/>
        </div>
    </div>
  )
}

export default HighlightedShop