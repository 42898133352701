import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { Widget } from '../../../widgetList/widgetList'
import ImageSelector from '../../ImageSelector'
import LinkForm, { LinkData } from '../LinkForm'

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

const FormForElectronicsApplience:FC<Props> = ({choosenItem, onUpdate}) => {
    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)

    const [active, setActive] = useState<boolean>(choosenItem.active)
    const [featuredImage, setFeatiredImage] = useState('')
    const [title, setTitle] = useState('')
    const [link, setLink] = useState<LinkData>({} as LinkData)
    const [fetcturedImagelinkTarget, setFetcturedImagelinkTarget] = useState('')
    const [choosenProductTypes, setChoosenProductTyeps] = useState<any[]>([])

    const [productTypes, setProductTypes] = useState([
        {
            id: 1,
            type: 'newest',
            status: '',
        },
        {
            id: 2,
            type: 'bestseller',
            status: '',
        },
        {
            id: 3,
            type: 'cheapest',
            status: '',
        },
        {
            id: 4,
            type: 'featured',
            status: '',
        },
        {
            id: 5,
            type: 'campaign',
            status: '',
        }
    ])

    useEffect(()=>{
        setFeatiredImage(choosenItem?.data?.electronicsApplience?.featuredImage?.image || '')
        setLink(choosenItem?.data?.electronicsApplience?.featuredImage?.url || {} as LinkData)
        setTitle(choosenItem?.data?.electronicsApplience?.featuredImage?.title || '')
        setFetcturedImagelinkTarget(choosenItem?.data?.electronicsApplience?.featuredImage?.target || '')
        setProductTypes(choosenItem?.data?.electronicsApplience?.productTypes || productTypes)
    },[choosenItem])

    useEffect(()=>{
        choosenAllImages.map((item: any) =>{
            return (
                setFeatiredImage(item.path)
            )
        })
    },[choosenAllImages])

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleLinkData = (data: LinkData) => {
        setLink(data)
    }

    const handleChooseProductTyepe = (productType: any) => {
        const _productTypes:any = productTypes.map(item=>{
            if(item.id === productType.id ){
                if(item.status === '') {
                    return {...item, status: 'selected'}
                }else {
                    return {...item, status: ''}
                }
            }
            return item
        })
        setProductTypes(_productTypes)
    }

    console.log("productTypes =>", productTypes)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        const widgetObj: any = {
            name: 'electronics-applience',
            active: active,
            data : {
                electronicsApplience: {
                    productTypes: productTypes,
                    featuredImage: {
                        image: featuredImage,
                        title: title,
                        url: link,
                        target: fetcturedImagelinkTarget,
                    }
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(widgetObj)
    }
    
    return (
        <>
        { choosenItem.name === 'electronics-applience' ?
            <form onSubmit={handleSubmit} className='mainSliderForm__container'>
                <h1 className='title capitalize'>Best Selling Products</h1>
                {/* <div className='checkbox'>
                    <div>Is Active: </div>
                    <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
                </div> */}
                <div>
                    <div>Featured Image: </div>
                    {
                        featuredImage.length > 0 ? 
                        <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + featuredImage} alt="" /> :
                        <img className='rounded-md mt-2' src="/img/image-placeholder.jpg" alt="" />
                    }
                    <input value={title} onChange={handleTitle} type="text" className='my-2' placeholder='Title' />
                    <LinkForm currnetLinkData={link} onUpdate={handleLinkData}/>

                    <div className='flex items-center gap-4 my-2'>
                        <div>Target: </div>
                        <div className='flex items-center gap-4'>
                            <div onClick={()=> {
                                    if(fetcturedImagelinkTarget === 'blank'){
                                        setFetcturedImagelinkTarget('')
                                    }else {
                                        setFetcturedImagelinkTarget('blank')
                                    }
                                }
                            } className='flex items-center gap-2 cursor-pointer'>
                                <div className={`w-5 h-5 rounded-md ${fetcturedImagelinkTarget === 'blank' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                <div>Blank</div>
                            </div>
                        </div>
                    </div>

                    <ImageSelector />

                    <div className='mt-4'>
                        <h1 className='title capitalize'>Products</h1>
                    </div>
                    
                    <div>
                        {
                            productTypes.map(product =>{
                                return(
                                    <div key={product?.id} className="flex items-center gap-3 cursor-pointer mt-2" onClick={()=>handleChooseProductTyepe(product)}>
                                        <div className={`w-4 h-4 rounded-md shadow-inne ${product?.status === 'selected' ? 'bg-green-400' : 'bg-gray-300'}`}></div>
                                        <div className='test-sm font-light tracking-wide capitalize'>{product?.type}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* <div className="grid grid-cols-2 gap-4 mt-4">
                        {
                            choosenProductTypes?.map((choosenProductType:any , index:number) =>{
                                return (
                                    <div key={index} className="bg-white shadow-lg py-2 rounded-md relative">
                                        <div className='test-xs font-light tracking-wide capitalize text-center truncate'>{choosenProductType}</div>
                                        <div className='absolute -right-2 -top-2 p-1 rounded-full text-red-500 bg-white shadow-lg'>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                </div>
                <div>
                    <button className='mainSliderForm__btn'>save</button>
                </div>
            </form>
            : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
        }
        </>
    )
}

export default FormForElectronicsApplience