import React, { FC, useEffect, useState, UIEvent } from 'react'
import { useParams } from 'react-router-dom'

import './menuDetails.css'

import type { AppDispatch, RootState } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { findMenu, CreatedMenu, SubMenu } from '../../features/addMenu'
import { useDetectClickOutside } from 'react-detect-click-outside';
import CaretLeft from '../../components/icons/CaretLeft'
import CaretRight from '../../components/icons/CaretRight'
import { updateMenu } from '../../modules/mediaManager'
import CloseIcon from '../../components/icons/CloseIcon'
import MenuForm from '../../components/MenuForm'
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import {_axios} from '../../api'
import { checkToken } from '../../App'
import Cookies from 'js-cookie'
import { openCategoryPopup, setGlobalPopupElement } from '../../features/popupSlice'
import CategorySearchV2 from '../../components/CategorySearchV2'
import { adminTokenCookieName } from '../../utils/constants'


export interface Category {
  id: number,
  logo: string,
  name: string,
  slug: string
}
export interface Page {
  name: string,
  slug: string,
}

export interface CategoryPagination {
    total: number,
    lastPage: number,
    currentPage: number
}

export interface NestingParams {
  type?:string,
  removeOnly?:boolean,
  updateOnly?: boolean
}

const MenuDetails:FC = () => {
  const menu = useSelector((state: RootState) => state.menus.menuDetail as CreatedMenu)
  const permissions = useSelector((state: RootState) => state.authPermission.permissions)

  const dispatch = useDispatch<AppDispatch>()

   //save reference for dragItem and dragOverItem
  const dragItem = React.useRef<any>(null)
  const [dragItemPosition, setDragItemPosition] = useState<string>('')
  const dragOverItem = React.useRef<any>(null)
  const [dragOverItemPosition, setDragOverItemPosition] = useState<string>('')
  const [id, setId] = useState<string>(useParams().id as string)

   //save reference for hoverItem
   const [hoverItem, setHoverItem] = useState<string | null>('')
  //level one
  const [menuStatus, setMenuStatus] = useState("draft")
  const [subMenus, setSubMenus] = useState<SubMenu[]>([])

  //level two form
  const [choosenItem, setChoosenItem] = useState<SubMenu>({} as SubMenu)
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [submenu, setSubmenu] = useState<SubMenu[]>([])
  const [type, setType] = useState<string>('custom')
  const [categoryText, setCategoryText] = useState<string>('')
  const [pageText, setPageText] = useState<string>('')
  const [categories, setCategories] = useState<Category[]>([])
  const [pages, setPages] = useState<Page[]>([])
  const [categoryPagination, setCategoryPagination] = useState<CategoryPagination>(
    {
    total: 0,
    lastPage: 1,
    currentPage: 1
  })
  const [pagePagination, setPagePagination] = useState<CategoryPagination>(
    {
    total: 0,
    lastPage: 1,
    currentPage: 1
  })
  const [showCategory, setShowCategory] = useState(true)
  const [showPage, setShowPage] = useState(true)
  const [canUpdateData, setCanUpdateData] = useState(false)

  const categorySuggestion = useDetectClickOutside({ onTriggered: () => setShowCategory(false) });
  const pageSuggestion = useDetectClickOutside({ onTriggered: () => setShowPage(false) });
  const hideEditForm = useDetectClickOutside({ onTriggered: () => unsetClickedItem });

  // const categoryPopup = useCategoryPopup()

  useEffect(()=>{
    dispatch(findMenu(id as string))
  },[dispatch, id])

  useEffect(()=> {
    setSubMenus(menu.menu)
    setCanUpdateData(true)
  },[menu.menu])

  useEffect(()=> {
    let updatedData = {...menu}
    updatedData.menu = subMenus
    updatedData.status = menuStatus
    if(canUpdateData) {
      updateNewData(updatedData)
    }
  },[canUpdateData, subMenus, menuStatus]);

  // useEffect(() => {

  //   if(categoryPopup.category) {
      
  //     const newItem: SubMenu = {
  //       name: categoryPopup.category.name,
  //       url: categoryPopup.category.slug,
  //       submenu: [],
  //       type: 'category'
  //     }
  //     setSubMenus(prev => [...prev, newItem]);
  //   }
  // }, [categoryPopup.category])

  const handleCategoryPopupOpen = () => {
    dispatch(setGlobalPopupElement((
      <CategorySearchV2 
        allClickable={true}
        onSelect={(category) => {
          const newItem: SubMenu = {
            name: category.name,
            url: category.slug,
            submenu: [],
            type: 'category'
          }

          setSubMenus(prev => [...prev, newItem]);
        }}
      />
    )))
  }

  const addMenu: SubMenu = {
    name,
    url,
    submenu,
    type
  }

  const updateNewData = (updatedData: CreatedMenu) => {
    updateMenu(updatedData).then(() => {
      if (updatedData.status === 'published') {
        swal("Good job!", "Menu published", "success");
        setMenuStatus('draft')
      }
    })
  }

  //level one functions
  const handleToggle = () => {
    if(menuStatus === 'draft') {
      setMenuStatus("published")
    } else {
      setMenuStatus("draft")
    }
  }

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setName(e.target.value)
  }

  const handleAddNewMenu = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const token = checkToken()
    if(token) {
      setSubMenus([...subMenus, addMenu])
      showToast('Added and saved as draft')
      setName('')
    }
  }

  
  const handleOnClick = (item: SubMenu, index:string) =>{
    setDragItemPosition(index)
    choosenItem.name && item.name === choosenItem.name ? unsetClickedItem() : setClickedItem(item, index)
  }

  const setClickedItem = (item: SubMenu, index:string) =>{
    setChoosenItem(item)
  }

  const unsetClickedItem = () =>{
    setDragItemPosition('')
    setChoosenItem({} as SubMenu)
  }


  const makeNestedChild = (item: SubMenu, index:string) => {
    const indecies = convertToIntegerIndex(index)
    let _updatedNestedSubMenus = JSON.parse(JSON.stringify(subMenus))
    let nestToPosition = [...indecies]
    nestToPosition[nestToPosition.length-1] = nestToPosition[nestToPosition.length-1] - 1
    nest(_updatedNestedSubMenus, indecies, nestToPosition, item, {type: 'shallow'}) 
  }

  const convertToIntegerIndex =(indexes:string) => {
    return indexes.length > 1 ? indexes.split('_').map(str => {
      return Number(str);
    }) : [Number(indexes)]
  }

  const unnestedChild = (item: SubMenu, index:string) => {
    const indecies = convertToIntegerIndex(index)
    let _updatedNestedSubMenus = JSON.parse(JSON.stringify(subMenus))
    let nestToPosition = [...indecies]
    nestToPosition.pop()
    nest(_updatedNestedSubMenus, indecies, nestToPosition ,item, {type: 'deep'}) 
  }

  const removeChild = (item: SubMenu, index:string) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this menu!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        const indecies = convertToIntegerIndex(index)
        let _updatedNestedSubMenus = JSON.parse(JSON.stringify(subMenus))
        let nestToPosition = [...indecies]
        nestToPosition.pop()
        nest(_updatedNestedSubMenus, indecies, nestToPosition, item, {removeOnly: true}) 
        swal("Removed!", {
          icon: "success",
          timer: 800,
          buttons: [false]
        });
      }
    });
  }

  const handleSort = (e: React.DragEvent<HTMLDivElement>) => {
    // return if nesting object to itself
    if(dragOverItemPosition.startsWith(dragItemPosition)) {
      dragItem.current = null
      dragOverItem.current = null
      return
    }
    
    const dropOnPosition:number[] = dragOverItemPosition.split('_').map(str => {
      return Number(str);
    });
    const dragPosition:number[] = dragItemPosition.split('_').map(str => {
      return Number(str);
    });
    if(dragPosition.length === 1) {
      if(dropOnPosition.length > 1 && dropOnPosition[0] > dragPosition[0]) {
        dropOnPosition[0] = dropOnPosition[0]-1
      } 
    }
    let _updatedSubMenus = JSON.parse(JSON.stringify(subMenus))

    nest(_updatedSubMenus, dragPosition, dropOnPosition ,dragItem.current, {}) 
    dragItem.current = null
    dragOverItem.current = null
    const element = e.target as HTMLImageElement
    element.style.opacity = "1"
  }

  const nest = (array: SubMenu[], deleteIndices: number[], insertIndicies: number[], toBeInserted:SubMenu, optionalParams: NestingParams):void => {
    if(!optionalParams.updateOnly){
      const deleteIndex:number = deleteIndices.pop() as number;
      // reduce the indices array to get the nested items array in every loop to get the final items array we want to do the delete operation on.
      const deleteItems = deleteIndices.reduce((acc, i) => acc[i].submenu, array);
      deleteItems.splice(deleteIndex, 1);
    }

    if(!optionalParams.removeOnly) {
      let insertIndex:number = optionalParams.type === 'shallow' ? lengthOfParent(insertIndicies) as number :  insertIndicies.pop() as number;
      // reduce the indices array to get the nested items array in every loop to get the final items array we want to do the delete operation on.
      const insertItems = insertIndicies.reduce((acc, i) => acc[i].submenu ? acc[i].submenu : acc[i].submenu = [], array);
      optionalParams.updateOnly ? insertItems.splice(insertIndex, 1, toBeInserted) : insertItems.splice(insertIndex + 1, 0, toBeInserted) 
      }
      setSubMenus(array)
      unsetClickedItem()
  }

  const lengthOfParent = (indecies: number[], array:SubMenu[] = subMenus) => {
    let _array = [...array]
    for(let i = 0; i < indecies.length; i++) {
        _array = _array[indecies[i]].submenu ? _array[indecies[i]].submenu : []
    }
    return _array.length
  } 

  const handleCategorySearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
    const target: string = e.target.value
    setCategoryText(target)
    setCategoryPagination({
      total: 0,
      lastPage: 1,
      currentPage: 1
    })
    target.length > 0 ? getCategories(target) : setShowCategory(false)
  }

  const handlePageSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
    const target: string = e.target.value
    setPageText(target)
    setPagePagination({
      total: 0,
      lastPage: 1,
      currentPage: 1
    })
    target.length > 0 ? getPages(target) : setShowPage(false)
  }

  const getCategories = async (searchText: string) => {
    checkToken()
    await _axios.get(process.env.REACT_APP_BASE_URL + `catalog/category?page=${categoryPagination.currentPage}&perPage=10&s=${searchText}`, {
      headers: {
          'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
      }
  }).then(res => {
      if (res.data.pagination.currentPage === 1) {
        let updatedPagination = {...categoryPagination, total: res.data.pagination.total, lastPage: res.data.pagination.lastPage, currentPage: res.data.pagination.currentPage}
        setCategoryPagination(updatedPagination)
        setCategories(res.data.data)
        } else {
          const updatedCategories= [...categories].concat(res.data.data)
          setCategories(updatedCategories)
        }
      setShowCategory(true)
    })
  }

  const getPages = async (searchText: string) => {
    checkToken()
    await _axios.get(process.env.REACT_APP_BUILDER_URL + `page?currentPage=${pagePagination.currentPage}&perPage=10&search=${searchText}`, {
      headers: {
          'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
      }
  }).then(res => {
      if (res.data.currentPage === 1) {
        let updatedPagination = {...pagePagination, total: res.data.total, lastPage: res.data.lastPage, currentPage: res.data.currentPage}
        setPagePagination(updatedPagination)
        setPages(res.data.data)
        } else {
          const updatedPages= [...pages].concat(res.data.data)
          setPages(updatedPages)
        }
      setShowPage(true)
    })
  }

  const handleCategoryScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if ((categoryPagination.currentPage < categoryPagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
      getCategories(categoryText)
      let updatePagination = {...categoryPagination, currentPage: categoryPagination.currentPage + 1}
      setCategoryPagination(updatePagination)
    }
  }

  const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if ((pagePagination.currentPage < pagePagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
      getPages(pageText)
      let updatePagination = {...pagePagination, currentPage: pagePagination.currentPage + 1}
      setPagePagination(updatePagination)
    }
  }

  const handleCategoryClick = (category: Category) => {
    setCategoryText('')
    const newItem: SubMenu = {
      name: category.name,
      url: category.slug,
      submenu: [],
      type: 'category'
    }
    setSubMenus([...subMenus, newItem])
    setShowCategory(false)
    showToast('Added and saved as draft')
    
} 

  const handlePageClick = (page: Page) => {
    setPageText('')
    const newItem: SubMenu = {
      name: page.name,
      url: sluggify(page.name),
      submenu: [],
      type: 'page'
    }
    setSubMenus([...subMenus, newItem])
    setShowPage(false)
    showToast('Added and saved as draft')
  }
  const sluggify = (toConvert:string): string => {
    return toConvert.toLowerCase().replace(/\s+/g, '_')
  }

  // const sluggify = (toConvert:string): string => {
  //   return toConvert.normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
  //   .toLowerCase()
  //   .replace(/\s+/g, '_') //spaces to dashes
  //   .replace(/[^\w\-]+/g, '') //remove non-words
  //   .replace(/\-\-+/g, '-') //collapse multiple dashes
  //   .replace(/\_\_+/g, '_') //collapse multiple dashes
  //   .replace(/^-+/, '') //trim starting dash
  //   .replace(/-+$/, '') //trim ending dash,
  // }

  const showToast = (message:string) => {
    checkToken()
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 2500
    });
    Toast.fire({
      icon: 'success',
      title: message
    })
  }

  
  // display all nested menues recurcievely
  const showChild = (submenu: SubMenu[], level: number, index?: number  | string): React.ReactNode => {
    return (
      submenu?.map((subitem, subIndex) => {
        const key: string = index ? `${index}_${subIndex}` : `${subIndex}`
        let level_padding = `${level*level}rem`
      return (
        <div key={key} className="">
          <div className={`bg-white w-60 mb-3 p-3 cursor-pointer rounded shadow-lg last:border-none flex items-center justify-between gap-4 relative`} style={{'marginLeft': level > 1 ? level_padding : 0}}
          onMouseEnter={() => (setHoverItem(key))}
          onMouseLeave={() => (setHoverItem(null))} ref={hideEditForm} onClick={()=>handleOnClick(subitem, key)}>
              <div
                onDragStart={() => {
                  setDragItemPosition(key)
                  dragItem.current = subitem
                }}
                onDragEnter={(e) => {
                  e.preventDefault()
                  setDragOverItemPosition(key)
                  dragOverItem.current = subitem
                  const target = e.target as HTMLImageElement
                  target.style.opacity = "0.2"
                }}
                onDragEnd={handleSort}
                onDragLeave={(e) =>{
                    e.preventDefault()
                    const target = e.target as HTMLElement
                    target.style.opacity = "1"
                  }
                }
                draggable="true"
                className='flex items-center justify-left gap-3 truncate pr-8'
              >
                {(hoverItem === key && key.length > 1) && <div onClick={(e) => {
                  e.stopPropagation()
                  unnestedChild(subitem, key)
                  }
                }><CaretLeft/></div>}
                <div className='truncate'>{subitem.name}</div>
                {(hoverItem === key && subIndex!== 0 ) && <div onClick={(e) => {
                  e.stopPropagation()
                  makeNestedChild(subitem, key)
                  }
                }><CaretRight/>
              </div>
              }
              <div className="absolute right-4" onClick={(e) => {
                e.stopPropagation()
                removeChild(subitem, key)
                }}><CloseIcon/></div>
              </div>
          </div>
          {dragItemPosition === key && 
            <div className="editOptions indivisual_widget">
              <MenuForm subMenus={subMenus && subMenus} choosenItem={choosenItem} chosenIndex={dragItemPosition} onUpdate={nest}/>
            </div>
          }
          {subitem.submenu?.length > 0 && showChild(subitem.submenu, level+1, key)}
        </div>
      )
    })
    )
  }

  return (
    <div className='menu_nest pagedetails__container'>
      <div className="pagedetails__row--one">
        <div className="tracking-wide">
          {menu.name}
        </div>
        {
          permissions.includes('publish_dynamic_menu') &&
          <div className='flex items-center gap-2'>
            <div className='text-sm font-light tracking-wide'>Publish :</div>
            <div>
              <div className={`w-12 h-fit rounded-full flex items-center p-1 shadow-inner cursor-pointer ${menuStatus === 'draft' ? 'justify-start bg-gray-400' : 'justify-end bg-green-500'}`} onClick={handleToggle}>
                <div className='w-4 h-4 rounded-full bg-white shadow-lg'></div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="pagedetails__row--two">
          <div className="pagedetails__col--one">
            <div className='pagedetails__col--one-wrapper'>
                <div>
                  <div>
                    <div className="tracking-wide mb-2">
                      Categories: 
                    </div>
                    <div className='mb-4'>
                      {/* <button
                        onClick={()=> {
                          categoryPopup.open();
                        }}
                      >Choose</button> */}
                      {/* <input type="text" placeholder='Search Categories...' value={categoryText} onChange={handleCategorySearch}/> */}
                      <input className='cursor-pointer' readOnly type="text" placeholder='Choose category...' onClick={() => {
                        // categoryPopup.open();
                        handleCategoryPopupOpen();
                      }}/>
                    </div>
                    <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                        {
                            categories.length > 0 && showCategory &&
                            <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar' ref={categorySuggestion} onScroll={handleCategoryScroll}>
                                {
                                    categories.map((category, index) => {
                                        return (
                                            <div key={index} onClick={()=>handleCategoryClick(category)}>
                                                <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                    <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                    <div>{category.id}</div>
                                                    <div className='text-sm font-light tracking-wide truncate'>{category.name}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                  </div>

                  <div>
                    <div className="tracking-wide mb-2">
                      Pages: 
                    </div>
                    <div className='mb-4'>
                      <input type="text" placeholder='Search Pages...' value={pageText} onChange={handlePageSearch}/>
                    </div>
                    <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                        {
                            (pages.length > 0 && showPage) &&
                            <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar' ref={pageSuggestion} onScroll={handlePageScroll}>
                                {
                                    pages.map((page, index) => {
                                        return (
                                            <div key={index} onClick={()=>handlePageClick(page)}>
                                                <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                    <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                    <div className='text-sm font-light tracking-wide truncate'>{page.name}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                  </div>

                  <div>
                    <div className="tracking-wide mb-2">
                      Custom: 
                    </div>
                    <div className="add-menu">
                      <form onSubmit={ handleAddNewMenu }>
                        <input value={ name } type="text" placeholder='Custom Name...' onChange={handleCategoryChange}/>
                        <button className={name.length > 0 ? 'btn' : 'dissabledSliderForm__btn'} type='submit' disabled={name.length === 0}>Add new</button>
                      </form>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="pagedetails__col--two no-scrollbar">
            <div>
              <div className={`${subMenus?.length > 0 && 'mt-3'}`}>
                {showChild(subMenus, 1)}
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default MenuDetails