import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { PageCategory, Widget } from '../../../widgetList/widgetList'
import ImageSelector, { ImageSelectorV2 } from '../../ImageSelector'
import { Image } from '../../../widgetList/widgetList'
import LogicBuilder from '../../LogicBuilder'
import LogicalBuilder from '../../LogicBuilder/LogicalBuilder'
import LinkForm, { LinkData } from '../LinkForm'
import CampaignSelector from '../../CampaignSelector'
import ImagePopUpV2 from '../../ImagePopUpV2'
import { setGlobalPopupElement } from '../../../features/popupSlice'
import { ImageContainerEditButton } from '../../components'
import TopPanel, { Panel } from '../TopPanel'
import ProductsDefaultSettings from '../ProductsDefaultSettings'

interface Props {
    choosenItem: any; // type: Widget
    onUpdate: (w: Widget) => void
}

const FormForPopulerProduct:FC<Props> = ({choosenItem, onUpdate}) => {
    const dispatch = useDispatch();

    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
    const customProductIds = useSelector((state: RootState) => state.logicBuilder.setting)

    const customProductsFromBuilder = customProductIds.custom
    const logicalProductsFromBulider = customProductIds.logical

    console.log('customProductsFromBuilder =>', customProductsFromBuilder.length)
    console.log('logicalProductsFromBulider =>', logicalProductsFromBulider)

    const [active, setActive] = useState<boolean>(choosenItem.active)

    const [featuredImage, setFeatiredImage] = useState('')
    const [title, setTitle] = useState('')
    const [link, setLink] = useState<LinkData>({} as LinkData)
    const [fetcturedImagePosition, setFetcturedImagePosition] = useState('left')
    const [sourceType, setSourceType] = useState('custom')
    const [fetcturedImagelinkTarget, setFetcturedImagelinkTarget] = useState('')

    const [selectedCampaign, setSelectedCampaign] = useState<PageCategory | null>(null);

    const [panel, setPanel] = useState<Panel>('main');
    const [settings, setSettings] = useState<any>(choosenItem?.data?.settings as any);

    useEffect(()=> {
        const customLenght: any = choosenItem?.data?.setting?.custom?.length
        if(customLenght > 0){
            setSourceType('custom')
        }else {
            setSourceType('logical')
        }
    },[choosenItem])

    useEffect(()=>{
        setFeatiredImage(choosenItem?.featuredImage || '')
        setFetcturedImagePosition(choosenItem?.fetcturedImagePosition || '')
        setLink(choosenItem?.fetchuredImageUrl as LinkData || {} as LinkData)
        setTitle(choosenItem.title || '')
    },[choosenItem])

    useEffect(()=>{
        choosenAllImages.map((item: any) =>{
            return (
                setFeatiredImage(item.path)
            )
        })
    },[choosenAllImages])

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        const widgetObj: any = {
            name: 'populer-product',
            active: active,
            imageLimit: 1,
			featuredImage: featuredImage,
			fetchuredImageUrl: link,
            fetcturedImagelinkTarget: fetcturedImagelinkTarget,
			fetcturedImagePosition: fetcturedImagePosition,
            data : {
                images: [],
                setting: sourceType === 'custom' ? { custom: customProductIds.custom,  logical: {} } : { custom: [],  logical: customProductIds.logical },
                settings: settings,
                productType: sourceType,
                selectedCampaign: selectedCampaign || choosenItem?.data?.selectedCampaign || null,
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(widgetObj)
    }

    const handleLinkData = (data: LinkData) => {
        setLink(data)
    }

    const handleImageSelector = (index?: number) => {
        dispatch(setGlobalPopupElement(
            <ImagePopUpV2
                single={true}
                onUpdate={images => {
                    const arrayImages = images.map((item: any) => {
                        return(
                                {
                                    image: item.path,
                                    title: item.title,
                                    link: {} as LinkData
                                }
                        )
                    })

                    // setImages(arrayImages)
                    const item = arrayImages[0];

                    setFeatiredImage(item.image) 

                    dispatch(setGlobalPopupElement(null));
                }}
                onClose={() => {
                    dispatch(setGlobalPopupElement(null));
                }}
            />
        ))
    }

    return (
        <>
        { choosenItem.name === 'populer-product' ?
            <form onSubmit={handleSubmit} className='mainSliderForm__container'>
                <h1 className='title capitalize'>populer product</h1>

                <TopPanel
                    panel={panel}
                    onChange={name => setPanel(name)}
                />
                {/* <div className='checkbox'>
                    <div>Is Active: </div>
                    <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
                </div> */}

                {panel === 'main' && (
                    <div>
                        <div>Featured Image: </div>
                        {
                            featuredImage.length > 0 ? 
                            (
                                <ImageContainerEditButton
                                    index={0}
                                    imageUrl={process.env.REACT_APP_IMAGE_URL + featuredImage}
                                    handleImageSelector={handleImageSelector}
                                />
                            ) :
                            // <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + featuredImage} alt="" /> :
                            <img className='rounded-md mt-2' src="/img/image-placeholder.jpg" alt="" />
                        }
                        <input value={title} onChange={handleTitle} type="text" className='my-2' placeholder='Title' />
                        
                        <LinkForm currnetLinkData={link} onUpdate={handleLinkData}/>

                        <div className='flex items-center gap-4 my-2'>
                            <div>Target: </div>
                            <div className='flex items-center gap-4'>
                                <div onClick={()=> {
                                        if(fetcturedImagelinkTarget === 'blank'){
                                            setFetcturedImagelinkTarget('')
                                        }else {
                                            setFetcturedImagelinkTarget('blank')
                                        }
                                    }
                                } className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${fetcturedImagelinkTarget === 'blank' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Blank</div>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center gap-4 mb-2'>
                            <div>Position: </div>
                            <div className='flex items-center gap-4'>
                                <div onClick={()=> setFetcturedImagePosition('left')} className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${fetcturedImagePosition === 'left' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Left</div>
                                </div>
                                <div onClick={()=> setFetcturedImagePosition('right')} className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${fetcturedImagePosition === 'right' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Right</div>
                                </div>
                            </div>
                        </div>

                        {/* <ImageSelector /> */}
                        <ImageSelectorV2 onClick={handleImageSelector} />

                        <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
                            <CampaignSelector
                                initialCampaign={choosenItem?.data?.selectedCampaign || null} 
                                onUpdate={(data) => {
                                    setSelectedCampaign(data);
                                }} 
                            />
                        </div>


                        <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
                            <div>Source type: </div>
                            <div className='flex items-center gap-4'>
                                <div onClick={()=> setSourceType('logical')} className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${sourceType === 'logical' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Logical</div>
                                </div>
                                <div onClick={()=> setSourceType('custom')} className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${sourceType === 'custom' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Custom</div>
                                </div>
                            </div>
                        </div>

                        <LogicBuilder sourceType={sourceType} choosenItem={choosenItem}/>

                    </div>
                )}

                {panel === 'settings' && (
                    <ProductsDefaultSettings
                        settings={settings}
                        onChange={(key, value) => {
                            setSettings({
                                ...settings,
                                [key]: value,
                            })
                        }}
                    />
                )}
                <div>
                    <button className='mainSliderForm__btn'>save</button>
                </div>
            </form>
            : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
        }
        </>
    )
}

export default FormForPopulerProduct