import React, { FC, useEffect, useState } from 'react'
import {_axios} from '../../../api'
import CloseIcon from '../../icons/CloseIcon'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { PageCategory, Widget } from '../../../widgetList/widgetList'
import { checkToken } from '../../../App'
import Cookies from 'js-cookie'
import CampaignSelector from '../../CampaignSelector'
import { adminTokenCookieName, envs } from '../../../utils/constants'

interface Props {
  choosenItem: Widget
  onUpdate: (w: Widget) => void
}

const FormForSingleProductSlider:FC<Props> = ({choosenItem, onUpdate}) => {
    console.log('choosenItem => ', choosenItem)

  const choosenCustomProducts = choosenItem?.data?.singleProductSlider?.products || []
  const [text, setText] = useState<string>('')
  const [products, setProdcuts] = useState<any[]>([])
  const [choosenProducts, setChoosenProducts] = useState<any[]>([])
  const [show, setShow] = useState<boolean>(true)

  const [selectedCampaign, setSelectedCampaign] = useState<PageCategory | null>(null);

  useEffect(()=>{
    setChoosenProducts(choosenCustomProducts)
  },[choosenItem])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
    const _text = e.target.value
    getProducts(_text.trim())
  }

  const getProducts = async(_text: string) => {
    checkToken()
    await _axios.get(envs.frontendApiUrl + 'product/storefront?search=' + _text, { headers: {
      "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
    }}).then(res =>{
      setProdcuts(res.data.data)
      setShow(true)
    })
  }

  const handleChoosenProduct = (product: any) => {
    const _choosenProducts = [...choosenProducts]
    _choosenProducts.push(product)
    setChoosenProducts(_choosenProducts)
    setText('')
  }

  const hideProductList = () => {
    setShow(false)
  }

  const handleRemoveProductId = (index: number) => {
    const filteredProducts = choosenProducts.filter((_,i)=> i !== index)
    setChoosenProducts(filteredProducts)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const singleProductSliderProject: any = {
        name: 'single-product',
        active: true,
        data : {
            singleProductSlider: {
                products: choosenProducts,
            },
            selectedCampaign: selectedCampaign || choosenItem?.data?.selectedCampaign || null,
        },
        wType: choosenItem.wType,
        id: choosenItem.id
    }
    onUpdate(singleProductSliderProject) 
  }

  const ref = useDetectClickOutside({ onTriggered: hideProductList });

  return (
    <>
    { choosenItem.name === 'single-product' ?
    <form onSubmit={handleSubmit} className='mainSliderForm__container'>
      <h1 className='title capitalize'>Single Product Slider</h1>
      <div>
        {
          <div className='w-full my-4'>
            {
              choosenProducts.map((item: Widget, index: number) => {
                return (
                  <div key={index} className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
                    <div className='capitalize text-xs font-light tracking-wide p-3'>
                      <div className='mb-2'><span className='font-bold'>Product: </span>{item?.name}</div>
                        <div><span className='font-bold'>Sku: </span>{item?.monarchmart_sku}</div>
                    </div>
                    <div onClick={()=>handleRemoveProductId(index)} className='absolute -top-3 -right-3 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg'>
                        <CloseIcon />
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </div>

        <div className='flex flex-col items-start gap-4 mt-3 pt-2 border-t'>
            <CampaignSelector
                initialCampaign={choosenItem?.data?.selectedCampaign || null} 
                onUpdate={(data) => {
                    setSelectedCampaign(data);
                }} 
            />
        </div>

      <div>
        <input value={text} type="text" placeholder='search...'  onChange={handleOnChange}/>
        <div>
              {
                products.length > 0 && show && 
                  <div className='bg-white px-3 shadow-lg divide-y max-h-96 min-h-fit overflow-y-scroll' ref={ref}>
                    {
                      products?.map(product => {
                        return (
                          <div key={product.id}>
                            <div className='flex flex-col items-center justify-between cursor-pointer py-2' onClick={()=>handleChoosenProduct(product)}>
                              <div className='text-xs font-light w-full'><span className='font-bold'>Product:</span> {product?.name}</div>
                              <div className='text-xs font-light w-full'><span className='font-bold'>Sku:</span> {product?.monarchmart_sku}</div>
                              <div className='text-xs font-light flex items-center w-full justify-start'>
                              <span className='font-bold'>Price:</span>
                              {
                                product.discount_price ? <div>
                                <span className='text-gray-400 line-through mr-3'>&#2547;{product?.purchase_price}</span><span className='text-red-500'>&#2547;{product?.discount_price}</span></div> : <div className='text-red-500'>{product.purchase_price}</div>
                              }
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
              }
        </div>
      </div>
        <div>
            <button className='mainSliderForm__btn'>save</button>
        </div>
    </form>
    : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForSingleProductSlider