import React from 'react'
import './mainslider.css'

const MainSlider:React.FC = () => {
  return (
    <div className='mainslider__container'>
        <div className="mainslider__wrapper">
            <img src="/img/widgets/mainslider.jpg" alt=''/>
        </div>
    </div>
  )
}

export default MainSlider