/* eslint-disable @typescript-eslint/no-empty-function */
import { CSSProperties, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalPopupElement } from '../../features/popupSlice';
import { CategoryItem } from '../../types/category';
import { getCategoryList } from '../../utils/requests';
import CloseIcon from '../icons/CloseIcon';

import './CategorySearchV2.css'

// import { CategoryItem } from '../../types/category';
// import from './CategorySearchV2.module.css'

const  any = {}

interface Props {
	onSelect?: (res :CategoryItem, breadcumb?: CategoryItem [])=> void;
	className?: string;
	style?: CSSProperties;
	allClickable?: boolean;
	currentCategory?: CategoryItem;
}

interface CategoryListProps {
	items: CategoryItem[];
	mainIndex?: number;
	breadcumb?: CategoryItem[];
	searchTexts?: string[];
	className?: string;
	onBack?: ()=> void;
	onClose?: ()=> void;
	onSelect?: (res :{
		item: CategoryItem;
		breadcumb: CategoryItem[];
		searchTexts: string[];
	})=> void;
	allClickable?: boolean;
	currentCategory?: CategoryItem;
}

const ArrowRightIcon = () => (
	<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008" stroke="#495057" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
)

/**
* @desc recursive list items
* @param items - MenuItems
* @returns JSX Element
* @author Azher Uddin <https://github.com/AzherUddin617>
* @date 2022-09-05 15:16:27
*/
export const CategoryList = ({
	items,
	className,
	mainIndex = 0,
	breadcumb = [],
	searchTexts = [],
	onBack = ()=> {},
	onClose = ()=> {},
	onSelect = ()=> {},
	allClickable = false,
	currentCategory
}: CategoryListProps ) => {
	const [activeIndex, setActiveIndx] = useState<number | null>(null);
	const [searchText, setSearchText] = useState<string>(searchTexts[mainIndex] || "");

	const [childCategories, setChildCategories] = useState<CategoryItem [] | null>(null);

	useEffect(() => {
		if (activeIndex !== null) {
			getCategoryList({
				parentId: items[activeIndex].id,
				status: 'active'
			}).then((res) => {
				setChildCategories(res.data);
			})
		}
	}, [activeIndex, items]);

	// useEffect(() => {
	//     setMenuIndex(activeIndex);
	// }, [activeIndex, setMenuIndex]);

	const classNames = 'CategoryList' + " "
					+ className + " ";

	if (activeIndex !== null) {
		breadcumb = [...breadcumb, items[activeIndex]];
	}

	searchTexts[mainIndex] = searchText;

	return (
		<>
			<ul className={classNames  + ' scrollbar-ui'}>
				<div className={'search hidden'}>
					<input 
						type="text" 
						placeholder="Search"
						value={searchText}
						onChange={(e)=> {
							e.preventDefault();
							setSearchText(e.target.value);
						}}
					/>

					{searchText && (
						<button 
							onClick={(e)=> {
								e.preventDefault();
								setSearchText("");
							}}
							className={'closeButton'}
						>
							<CloseIcon />
						</button>
					)}
				</div>

				{items.map((item, index) => {
					if (index > 200) return null;
					if (searchText && !item.name.toLowerCase().includes(searchText.toLowerCase())) return null;

					const disabled = item.id === currentCategory?.id;

					return (
						<li key={index} 
							className={'item' + " " + (activeIndex === index ? 'active' : "") + " " + (disabled ? 'disabled !text-gray-700 !cursor-not-allowed' : "")}
							onClick={(e)=> {
								e.preventDefault();
								if (disabled) return;
								setActiveIndx(index);
							}}
						>
	
							{/* <Link href={getPageRoute(item.type, item.url)} passHref> */}
								{/* <a href={getPageRoute(item.type, item.url)}> */}
								<h3 onClick={()=> {
									// onSelect(item);
								}} className={'title'}>{item.name}</h3>
								{/* </a> */}
							{/* </Link> */}
	
							{!item.leaf && (
								<button 
									disabled={disabled}
									onClick={(e) => {
										e.preventDefault();
										setActiveIndx(index);
									}}
									className={'icon disabled:!cursor-not-allowed'}
								>
									<ArrowRightIcon />
								</button>
							)}
						</li>
					)
				})}
			</ul>

			{activeIndex !== null && !!childCategories && (
				<CategoryList 
					key={activeIndex}
					items={childCategories || items} 
					breadcumb={breadcumb}
					mainIndex={mainIndex + 1}
					searchTexts={searchTexts}
					className={className} 
					onBack={() => setActiveIndx(null)}
					onClose={onClose}
					onSelect={onSelect}
					currentCategory={currentCategory}
					allClickable={allClickable}
				/>
			)}

			{((activeIndex !== null && items[activeIndex].leaf) || (allClickable && activeIndex === null)) && breadcumb.length > 0 && (
				<div className={'breadcumb'}>
					{breadcumb.map((item, index) => (
						<div key={index} className={'breadcumbItem'}>
							<button className='text-[0.8rem]' onClick={() => {}}>{item.name}</button>
							{index < breadcumb.length - 1 && <ArrowRightIcon />}
						</div>
					))}

					<button 
						className='py-2 px-4 bg-orange-500 text-white rounded text-[0.8rem]'
						onClick={() => {
							onSelect({
								item: breadcumb[breadcumb.length - 1],
								breadcumb: breadcumb,
								searchTexts: searchTexts
							});
						}}
					>Insert</button>
				</div>
			)}
		</>
	)
}


/**
* @desc categroy search popup
* @param className, style
* @returns JSX.Element - CategorySearchV2 component
* @author Azher Uddin <https://github.com/AzherUddin617>
* @date 2022-12-13 09:33:37
*/
const CategorySearchV2 = ({
	onSelect = () => {},
	allClickable = false,
	currentCategory,
	className,
	style,
}: Props) => {
	const dispatch = useDispatch();

	const [categoryItems , setCategoryItems] = useState<CategoryItem []>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const classNames = ['CategorySearchV2', className];

	useEffect(()=> {
		// fetch category items
		getCategoryList({
			parent: true,
			status: 'active'
		}).then(data => {
			setCategoryItems(data.data || []);
		}).catch(err => {
			setError('Error while fetching category items');
		}).finally(() => {
			setLoading(false);
		})
	}, []);

	const handleClose = () => {
		dispatch(setGlobalPopupElement(null));
	}

	return (
		<div className={classNames.join(' ')} style={style}>
			<div className='absolute left-0 right-0 w-full h-full -z-10' onClick={handleClose}></div>
			
			<div className={'contents bg-white'}>
				<button
					className={'close'}
					onClick={handleClose}
				>
					<CloseIcon />
				</button>

				{loading && <div>Loading...</div>}

				{error && <div>{error}</div>}

				<div className={'items bg-white relative'}>
					<CategoryList 
						items={categoryItems}
						allClickable={allClickable}
						currentCategory={currentCategory}
						className={'list'}
						onSelect={(data) => {
							onSelect(data.item, data.breadcumb);

							// dispatch(clearCategoryPopup());

							dispatch(setGlobalPopupElement(null));
						}}
					/>
				</div>

				{/* {categoryItems.slice(0, 10).map(item => (
					<div key={item.id}
						onClick={() => {
							dispatch(setCategoryPopup(item));
						}}
					>
						{item.name}
					</div>
				))} */}
			</div>
		</div>
	)
}

export default CategorySearchV2