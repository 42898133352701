import React, { FC, useEffect, useState, UIEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Alert from '../../components/global/Alert'; 
import CloseIcon from '../../components/icons/CloseIcon';
import CopyIcon from '../../components/icons/CopyIcon';
import { getImageDetails, openImagesDetails } from '../../features/ImageDetails/imageDetails';
import { fetchImagesModule, uploadImage, deleteImage } from '../../modules/mediaManager'
import { getImageUrl } from '../../utils';

import './mediaPage.css'


export interface Image {
    id: number,
    url: string
}

export interface PagePagination {
    total: number,
    lastPage: number,
    currentPage: number,
    totalResult?: number,
    totalPage?: number,
}


const MediaPage:FC = () => {
    const permissions = useSelector((state: RootState) => state.authPermission.permissions)

    const dispatch = useDispatch()

    const [showAlert, setShowAlert] = useState(false)
    const [images, setImages] = useState<MediaItem []>([])
    const [pagePagination, setPagePagination] = useState<PagePagination>({
        total: 0,
        lastPage: 1,
        currentPage: 1
    })

    const getImages = async(pagination: PagePagination) =>{
        try {
            const fileList = await fetchImagesModule(pagination)
            pagination.total === 0 ? setImages(fileList.data) : setImages(images.concat(fileList.data))
            if(fileList.total) {
                const {data, ...pagination} = fileList;
                setPagePagination({
                    ...pagination,
                    total: fileList.totalResult,
                    lastPage: fileList.totalPage
                })
            }
        } catch (error) {
            throw error
        }
    }

    useEffect(()=>{
        getImages(pagePagination)
    }, [])

    const handleUploadImage = (e: React.ChangeEvent) => {
        const target= e.target as HTMLInputElement;
        const items = (target.files as FileList);
        const arrayOfItems = Object.values(items)
        // eslint-disable-next-line array-callback-return
        arrayOfItems.map(async (item: File) => {
           const uploadedImages = await uploadImage(item)
           if(uploadedImages) {
            getImages({
                total: 0,
                lastPage: 1,
                currentPage: 1
            })
           }
        })
    }

    const handleCopyUrl = (image: any) => {
        dispatch(getImageDetails(image))
        dispatch(openImagesDetails())
    }

    const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        if ((pagePagination.currentPage < pagePagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
            const updatedPagination = {...pagePagination, currentPage: pagePagination.currentPage + 1}
            getImages(updatedPagination)
            setPagePagination(updatedPagination)
        }
    }

    const handleDeleteImage = async(id: number) => {
        const updatedData = await deleteImage(id, pagePagination)
        setImages(updatedData.data)
    }

  return (
    <div className='overflow-hidden'>
        {
            permissions.includes('create_media_manager') &&
            <div className="">
                <input onChange={(e)=>handleUploadImage(e)} className="p-8" type="file" accept="image/png, image/jpeg, image/svg" multiple/>
            </div>
        }
        <div className='grid grid-cols-6 gap-6 py-6 mt-4 max-h-[70vh] overflow-x-hidden overflow-y-scroll' onScroll={handlePageScroll}>
            {
                images?.map(image=> {
                    return (
                        <div key={image?.id} className="media__page--img-btn w-full h-full relative bg-white p-2 shadow-lg rounded-md">
                            <img className="w-full h-32 aspect-auto object-contain object-center cursor-pointer" src={getImageUrl(image?.path)} alt="" />
                            <div className='media__page--btn absolute -top-2 right-7 bg-white text-blue-500 rounded-full shadow-lg p-2 cursor-pointer' onClick={()=>handleCopyUrl(image)}>
                                <CopyIcon/>
                            </div>
                            {
                                permissions.includes('delete_media_manager') &&
                                <div className='media__page--btn absolute -top-2 -right-2 bg-white text-red-500 rounded-full shadow-lg p-1 cursor-pointer' onClick={()=>handleDeleteImage(image?.id)}>
                                    <CloseIcon/>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
        {
            showAlert &&
            <Alert title="image upload successfully!" setTimeout={setTimeout} />
        }
    </div>
  )
}

export default MediaPage