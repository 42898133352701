import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { openImagesPopUp, getImagePopUpType } from '../../features/ShowImagePopUp/showImagePopUp'

import './imageSelector.css'
import { setGlobalPopupElement } from '../../features/popupSlice'
import ImagePopUpV2 from '../ImagePopUpV2'
import { LinkData } from '../widgetFrom/LinkForm'

interface Props {
    type?: string
}

const ImageSelector:FC<Props> = ({type}) => {
    const dispatch = useDispatch()
    
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(openImagesPopUp())
        dispatch(getImagePopUpType(type))
    }

    return (
        <div className='imageSelector__container'>
            <div>
                <button onClick={(e) => handleClick(e)} className='imageSelector__btn'>choose images</button>
            </div>
        </div>
    )
}

export const ImageSelectorV2:FC<{
    onClick?: () => void;
}> = ({
    onClick = () => {},
}) => {

    return (
        <div className='imageSelector__container'>
            <div>
                <button onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }} className='imageSelector__btn'>choose images</button>
            </div>
        </div>
    )
}

interface PropsS {
    type: 'single';
    onChange: (image: string) => void;
}

interface PropsM {
    type: 'multiple';
    onChange: (images: string []) => void;
}

export const ImageSelectorV3 = ({
    type,
    onChange,
}: (
    PropsS | PropsM
)) => {
    const dispatch = useDispatch()

    const handleImageSelector = () => {
        dispatch(setGlobalPopupElement(
            <ImagePopUpV2
                single={type === 'single'}
                onUpdate={images => {
                    const arrayImages = images.map((item: any) => {
                        return(
                                {
                                    image: item.path,
                                    title: item.title,
                                    link: {} as LinkData
                                }
                        )
                    })

                    // setImages(arrayImages)
                    const item = arrayImages[0];

                    if (type === 'single') onChange(item.image);
                    else onChange(arrayImages.map(item => item.image));

                    dispatch(setGlobalPopupElement(null));
                }}
                onClose={() => {
                    dispatch(setGlobalPopupElement(null));
                }}
            />
        ))
    }

    return (
        <div className='imageSelector__container'>
            <div>
                <button onClick={(e) => {
                    e.preventDefault();
                    handleImageSelector();
                }} className='imageSelector__btn'>choose image{type === 'multiple' && 's'}</button>
            </div>
        </div>
    )
}

export default ImageSelector

