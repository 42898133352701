import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { handleContentEditorData, handleDataSave, openContentEditor } from '../../../features/contentEditor/contentEditor'
import { Widget } from '../../../widgetList/widgetList'

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}


const FormForContentEditor:FC<Props> = ({choosenItem, onUpdate}) => {
  const [content, setContent] = useState<any>(choosenItem.data?.content || '')
  const data = useSelector((state: RootState) => state.contentEditor.content)
  const dataSave = useSelector((state: RootState) => state.contentEditor.dataSave)
  const [update, setUpdate] = useState(false)
  console.log('dataSave =>', dataSave)

  useEffect(()=>{
    setContent(data)
  },[data])

  useEffect(()=>{
    setContent(choosenItem.data?.content)
  },[choosenItem])

  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(openContentEditor())
    dispatch(handleContentEditorData(content))
    setUpdate(true)
  }
  const handleUpdate = () => {
        const widgetObj:any = {
            name: 'content-editor',
            active: true,
            data : {
                content: content
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(widgetObj)
        setUpdate(false)
        dispatch(handleDataSave(false))
  }
  console.log('update =>', update)
  return (
    <>
    { choosenItem.name === 'content-editor' ?
        <div className='mainSliderForm__container'>
            <h1 className='title capitalize'>content editor (ID: {choosenItem.id})</h1>
            <div className='mt-6'>
              {
                !dataSave ? 
                <button onClick={handleClick} className='mainSliderForm__btn'>Edit Content</button> :
                <button onClick={handleUpdate} className='mainSliderForm__btn'>Update Content</button>
              }
            </div>
        </div>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForContentEditor