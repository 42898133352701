import React from 'react'
import { Link } from "react-router-dom";
import ArrowIcon from '../../../icons/ArrowIcon'
import DashboardIcon from '../../../icons/DashboardIcon'
import WidgetIcons from '../../../icons/WidgetIcon'
import PageIcon from '../../../icons/PageIcon'
import ListTreeIcon from '../../../icons/ListTreeIcon';

import './sidebarItem.css'

import type { RootState } from '../../../../app/store'
import { useSelector } from 'react-redux'

interface Props {
    title: string,
    icon: string,
    url: string
}

const SideBarItem:React.FC<Props> = ({ title, icon, url }) => {
    const showSideBar = useSelector((state: RootState) => state.showSideBar.value)
    return (
        <Link to={`/${url}`} className={`sidebar__item ${showSideBar ? '' : 'justify-center'}`}>
            <div className={showSideBar ? 'w-[30%]' : 'w-auto'}>
                {icon === 'one' ? <DashboardIcon /> : icon === 'two' ? <WidgetIcons /> : icon === 'three' ? <PageIcon /> : icon === 'four' ? <ListTreeIcon/>: <DashboardIcon />}
            </div>
            <p className={`sidebar__item--title ${showSideBar ? 'block' : 'hidden'}`}>{title}</p>
            { showSideBar &&
                <div className='w-[10%]'>
                    <ArrowIcon />
                </div>
            }
        </Link>
    )
}

export default SideBarItem