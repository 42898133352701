import {_axios} from '../../../api'
import React, { FC, useEffect, useState } from 'react'
import { Widget } from '../../../widgetList/widgetList'
import { useDetectClickOutside } from 'react-detect-click-outside';
import CloseIcon from '../../icons/CloseIcon';
import Category from '../../Widgets/Category';
import { checkToken } from '../../../App';
import Cookies from 'js-cookie';
import { adminTokenCookieName } from '../../../utils/constants';
import { getBrandList } from '../../../utils/requests';

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

interface Category {
    id: number,
    logo: string,
    name: string,
    slug: string
}

const FormForBarndList:FC<Props> = ({choosenItem, onUpdate}) => {
    console.log('choosenItem =', choosenItem)

    const [active, setActive] = useState(false)
    const [text, setText] = useState('')
    const [brands, setBrands] = useState<Category[]>([])
    const [show, setShow] = useState(true)
    const [choosenBrands, setChoosenBrands] = useState<Category[]>([])
    const [brandArray, setBrandArray] = useState<number[]>([])

    useEffect(()=>{
        setActive(choosenItem.active)
        setBrandArray(choosenItem.brandList || [])
        setChoosenBrands(choosenItem.brandList || [])
    },[choosenItem])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setText(e.target.value)
        getBrands(e.target.value)
    }

    const getBrands = async(text: string) => {
        checkToken()
        getBrandList({
            search: text,
            status: 'active'
        }).then(data => {
            setBrands(data.data as any)
            setShow(true)
        })
    }

    const hideBrandList = () => {
        setShow(false)
    }

    const handleClick = (brand: Category, index: number) => {
        const newArray = [...choosenBrands]
        newArray.push(brand)
        setChoosenBrands(newArray.reverse())

        // let newCategoryArray:number[] = [] 
        // newArray.reverse().forEach((item: Category)=>{
        //     newCategoryArray.push(item.id)
        // })
        // setCategoryArray(newCategoryArray)

        const newCategoryArray = [...brandArray]
        newCategoryArray.push(brand.id)
        setBrandArray(newCategoryArray)
    } 

    const removeCategory = (index: number) =>{
        const filteredCategories = choosenBrands.filter((_,i)=> i !== index)
        setChoosenBrands(filteredCategories)
    }
 
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const brandObj = {
            name: 'brand-list',
            active: active,
            brandList: choosenBrands,
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(brandObj)
    }

    const handleRemoveId = (index :number) => {
        const filteredCategory = choosenBrands.filter((_,i) => i !== index)
        setChoosenBrands(filteredCategory)
    }

    const ref = useDetectClickOutside({ onTriggered: hideBrandList });

  return (
    <>
    { choosenItem.name === 'brand-list' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>Brand List</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            <div>
                {
                    brandArray.length > 0 && 
                    <div className=''>
                        <div className='capitalize'>selected brands:</div>
                        <div className="mt-4">
                        {
                            choosenBrands.map((item: Category, index: number)=>{
                                return (
                                    <div key={index} className="relative h-12 bg-white shadow-lg rounded-md mb-4 flex items-center w-full">
                                        <div className='flex items-center justify-between text-sm font-light tracking-wide w-full px-3'>
                                            <div className='w-[80%] truncate'>{item.name}({item.slug})</div>
                                            <div className='w-[20%]'>ID:{item.id}</div>
                                        </div>
                                        <div onClick={()=>handleRemoveId(index)} className='absolute -top-3 -right-3 bg-white text-red-500 rounded-full shadow-lg p-1 cursor-pointer'>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                }
            </div>
            <div>
                {/* <div>
                    {
                        choosenCategories.length > 0 && 
                        <div>
                            {
                                choosenCategories.map((choosenCategory, index) => {
                                    return(
                                        <div key={index} className="bg-white shadow-lg mb-3 px-3 rounded-md relative">
                                            <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                <div className='text-sm font-light tracking-wide truncate'>{choosenCategory.name}</div>
                                            </div>
                                            <div onClick={() => removeCategory(index)} className='absolute z-10 -top-3 -right-3 bg-white text-red-500 rounded-full p-1 shadow-lg cursor-pointer'>
                                                <CloseIcon/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div> */}
                <div>
                    <input type="text" placeholder='Search brands...' onChange={handleSearch}/>
                </div>
                <div>
                    {
                        brands.length > 0 && show &&
                        <div className='divide-y max-h-64 min-h-fit overflow-y-scroll' ref={ref}>
                            {
                                brands.map((brand, index) => {
                                    return (
                                        <div key={index} onClick={()=>handleClick(brand, index)}>
                                            <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                <div>{brand.id}</div>
                                                <div className='text-sm font-light tracking-wide truncate'>{brand.name}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForBarndList