import React, { FC } from 'react'

const ComtentEditor:FC = () => {
  return (
    <div className='title__container'>
        <div className="title__wrapper">
            <img src="/img/widgets/content-editor.png" alt=''/>
        </div>
    </div>
  )
}

export default ComtentEditor