import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { Image, IndemandObj } from '../../../widgetList/widgetList'
import { Widget } from '../../../widgetList/widgetList'
import ImageSelector from '../../ImageSelector'
import LinkForm, { LinkData } from '../LinkForm'

import { SketchPicker } from 'react-color';
import CloseIcon from '../../icons/CloseIcon'


interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

const FormForInDemand:FC<Props> = ({choosenItem, onUpdate}) => {
    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
    console.log('choosenItem =', choosenItem.data?.inDemandObj?.inDemandObjOne)
    const showImagePopUpType = useSelector((state: RootState) => state.showImagePopUp.type)

    const [active, setActive] = useState<boolean>(choosenItem.active)

    const [imageOne, setImageOne] = useState('')
    const [imageTwo, setImageTwo] = useState('')
    const [imageThree, setImageThree] = useState('')
    const [imageFour, setImageFour] = useState('')

    const [imageOneTitle, setImageOneTitle] = useState('')
    const [imageTwoTitle, setImageTwoTitle] = useState('')
    const [imageThreeTitle, setImageThreeTitle] = useState('')
    const [imageFourTitle, setImageFourTitle] = useState('')

    const [imageOneSubTitle, setImageOneSubTitle] = useState('')
    const [imageTwoSubTitle, setImageTwoSubTitle] = useState('')
    const [imageThreeSubTitle, setImageThreeSubTitle] = useState('')
    const [imageFourSubTitle, setImageFourSubTitle] = useState('')

    const [imageOneLink, setImageOneLink] = useState<LinkData>({} as LinkData)
    const [imageTwoLink, setImageTwoLink] = useState<LinkData>({} as LinkData)
    const [imageThreeLink, setImageThreeLink] = useState<LinkData>({} as LinkData)
    const [imageFourLink, setImageFourLink] = useState<LinkData>({} as LinkData)

    const [showColorPicker, setShowColorPicker] = useState(false)
    const [bgColor, setBgColor] = useState<any>()

    const dispatch = useDispatch()

    useEffect(()=>{
        setBgColor({
            background: choosenItem.data?.backgroundColor
        })

        setImageOne(choosenItem.data?.inDemandObj?.inDemandObjOne?.image || '')
        setImageTwo(choosenItem.data?.inDemandObj?.inDemandObjTwo?.image || '')
        setImageThree(choosenItem.data?.inDemandObj?.inDemandObjThree?.image || '')
        setImageFour(choosenItem.data?.inDemandObj?.inDemandObjFour?.image || '')

        setImageOneTitle(choosenItem.data?.inDemandObj?.inDemandObjOne?.title || '')
        setImageTwoTitle(choosenItem.data?.inDemandObj?.inDemandObjTwo?.title || '')
        setImageThreeTitle(choosenItem.data?.inDemandObj?.inDemandObjThree?.title || '')
        setImageFourTitle(choosenItem.data?.inDemandObj?.inDemandObjFour?.title || '')

        setImageOneSubTitle(choosenItem.data?.inDemandObj?.inDemandObjOne?.subTitle || '')
        setImageTwoSubTitle(choosenItem.data?.inDemandObj?.inDemandObjTwo?.subTitle || '')
        setImageThreeSubTitle(choosenItem.data?.inDemandObj?.inDemandObjThree?.subTitle || '')
        setImageFourSubTitle(choosenItem.data?.inDemandObj?.inDemandObjFour?.subTitle || '')

        setImageOneLink((choosenItem.data?.inDemandObj?.inDemandObjOne?.link) as LinkData)
        setImageTwoLink((choosenItem.data?.inDemandObj?.inDemandObjTwo?.link) as LinkData)
        setImageThreeLink((choosenItem.data?.inDemandObj?.inDemandObjThree?.link) as LinkData)
        setImageFourLink((choosenItem.data?.inDemandObj?.inDemandObjFour?.link) as LinkData)
    },[choosenItem])

    useEffect(()=>{
        if(showImagePopUpType === 'indemand-one' || showImagePopUpType === 'indemand-two' || showImagePopUpType === 'indemand-three' || showImagePopUpType === 'indemand-four'){
            choosenAllImages.map((item: any) =>{
                if(showImagePopUpType === 'indemand-one'){
                    return (
                        setImageOne(item.path)
                    )
                }
                if(showImagePopUpType === 'indemand-two') {
                    return (
                        setImageTwo(item.path)
                    )
                }
                if(showImagePopUpType === 'indemand-three') {
                    return (
                        setImageThree(item.path)
                    )
                }
                if(showImagePopUpType === 'indemand-four') {
                    return (
                        setImageFour(item.path)
                    )
                }
            })
        }
    },[choosenAllImages])

    const handleShowImage = () => {
        setShowColorPicker(!showColorPicker)
    }

    const handleBgColor = (color: any) =>{
        setBgColor({ background: color.hex });
    }

    const handleLinkDataOne = (data: LinkData) => {
        setImageOneLink(data)
    }

    const handleLinkDataTwo = (data: LinkData) => {
        setImageTwoLink(data)
    }

    const handleLinkDataThree = (data: LinkData) => {
        setImageThreeLink(data)
    }

    const handleLinkDataFour = (data: LinkData) => {
        setImageFourLink(data)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const inDemandObjOne: IndemandObj = {
            image: imageOne,
            title: imageOneTitle,
            subTitle: imageOneSubTitle,
            link: imageOneLink
        }

        const inDemandObjTwo: IndemandObj = {
            image: imageTwo,
            title: imageTwoTitle,
            subTitle: imageTwoSubTitle,
            link: imageTwoLink
        }

        const inDemandObjThree: IndemandObj = {
            image: imageThree,
            title: imageThreeTitle,
            subTitle: imageThreeSubTitle,
            link: imageThreeLink
        }

        const inDemandObjFour: IndemandObj = {
            image: imageFour,
            title: imageFourTitle,
            subTitle: imageFourSubTitle,
            link: imageFourLink
        }

        const inDemandObj:any = {
            inDemandObjOne,
            inDemandObjTwo,
            inDemandObjThree,
            inDemandObjFour
        }

        const widgetObj = {
            name: 'in-demand',
            title: '',
            active: active,
            data : {
                backgroundColor: bgColor?.background,
                inDemandObj: inDemandObj
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        console.log('widgetObj =>', widgetObj)
        onUpdate(widgetObj)
    }

    return (
    <>
    { choosenItem.name === 'in-demand' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title'>In Demand</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            <div className='mt-3 border-b pb-4'>
                Background Color: {bgColor?.background}
                <div className='w-full h-20 bg-gray-300 rounded-md shadow-md mt-2' style={{ backgroundColor: bgColor?.background }}></div>
                {showColorPicker &&
                    <div className='mt-4 relative'>
                        <SketchPicker color={ bgColor } onChangeComplete={ handleBgColor }/>
                        <div onClick={() => setShowColorPicker(false)} className='absolute -top-3 -right-1 bg-white text-red-500 shadow p-1 rounded-full cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>
                }
                <div onClick={handleShowImage} className='imageSelector__btn flex items-center justify-center cursor-pointer mt-6'>Open Color Picker</div>
            </div>
            <div className='mt-3 border-b pb-4'>
                <p className='text-xs font-light capitalize'>In Demand Image One:</p>
                {
                    imageOne.length > 0 ? 
                    <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + imageOne} alt="" /> :
                    <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                }
                <div>
                    <input value={imageOneTitle} onChange={(e)=> setImageOneTitle(e.target.value)} className='mt-4' type="text" placeholder='Title....' />
                    <input value={imageOneSubTitle} onChange={(e)=> setImageOneSubTitle(e.target.value)} className='mt-4' type="text" placeholder='Sub Title...' />
                    {/* <input value={imageOneLink} onChange={(e)=> setImageOneLink(e.target.value)} className='mt-4' type="text" placeholder='Link...' /> */}
                    <LinkForm currnetLinkData={imageOneLink} onUpdate={handleLinkDataOne} selectedImage={imageOne}/>
                </div>
                <div className='mt-4'>
                    <ImageSelector type="indemand-one"/>
                </div>
            </div>
            <div className='mt-3 border-b pb-4'>
                <p className='text-xs font-light capitalize'>In Demand Image Two:</p>
                {
                    imageTwo.length > 0 ? 
                    <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + imageTwo} alt="" /> :
                    <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                }
                <div>
                    <input value={imageTwoTitle} onChange={(e)=> setImageTwoTitle(e.target.value)} className='mt-4' type="text" placeholder='Title....' />
                    <input value={imageTwoSubTitle} onChange={(e)=> setImageTwoSubTitle(e.target.value)} className='mt-4' type="text" placeholder='Sub Title...' />
                    {/* <input value={imageTwoLink} onChange={(e)=> setImageTwoLink(e.target.value)} className='mt-4' type="text" placeholder='Link...' /> */}
                    <LinkForm currnetLinkData={imageTwoLink} onUpdate={handleLinkDataTwo} selectedImage={imageTwo}/>
                </div>
                <div className='mt-4'>
                    <ImageSelector type="indemand-two"/>
                </div>
            </div>
            <div className='mt-3 border-b pb-4'>
                <p className='text-xs font-light capitalize'>In Demand Image Three:</p>
                {
                    imageThree.length > 0 ? 
                    <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + imageThree} alt="" /> :
                    <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                }
                <div>
                    <input value={imageThreeTitle} onChange={(e)=> setImageThreeTitle(e.target.value)} className='mt-4' type="text" placeholder='Title....' />
                    <input value={imageThreeSubTitle} onChange={(e)=> setImageThreeSubTitle(e.target.value)} className='mt-4' type="text" placeholder='Sub Title...' />
                    {/* <input value={imageThreeLink} onChange={(e)=> setImageThreeLink(e.target.value)} className='mt-4' type="text" placeholder='Link...' /> */}
                    <LinkForm currnetLinkData={imageThreeLink} onUpdate={handleLinkDataThree} selectedImage={imageThree}/>
                </div>
                <div className='mt-4'>
                    <ImageSelector type="indemand-three"/>
                </div>
            </div>
            <div className='mt-3 border-b pb-4'>
                <p className='text-xs font-light capitalize'>In Demand Image Four:</p>
                {
                    imageFour.length > 0 ? 
                    <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + imageFour} alt="" /> :
                    <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                }
                <div>
                    <input value={imageFourTitle} onChange={(e)=> setImageFourTitle(e.target.value)} className='mt-4' type="text" placeholder='Title....' />
                    <input value={imageFourSubTitle} onChange={(e)=> setImageFourSubTitle(e.target.value)} className='mt-4' type="text" placeholder='Sub Title...' />
                    {/* <input value={imageFourLink} onChange={(e)=> setImageFourLink(e.target.value)} className='mt-4' type="text" placeholder='link...' /> */}
                    <LinkForm currnetLinkData={imageFourLink} onUpdate={handleLinkDataFour} selectedImage={imageFour}/>
                </div>
                <div className='mt-4'>
                    <ImageSelector type="indemand-four"/>
                </div>
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForInDemand