import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { handlePageWidget } from '../../../features/addPages/addPages'
import { setGlobalPopupElement } from '../../../features/popupSlice'
import { Widget } from '../../../widgetList/widgetList'
import { ImageContainerEditButton } from '../../components'
import CloseIcon from '../../icons/CloseIcon'
import ImagePopUpV2 from '../../ImagePopUpV2'
import ImageSelector, { ImageSelectorV2 } from '../../ImageSelector'
import LinkForm, { LinkData } from '../LinkForm'
import ImageSortWithTitle from '../../ImageSortWithTitle'

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}
interface Image {
    image: string,
    title: string,
    link: LinkData
}

type Panel = 'main' | 'settings';
const panels: {
    name: Panel;
    title: string;
} [] = [
    {
        name: 'main',
        title: 'Main',
    },
    {
        name: 'settings',
        title: 'Settings',
    },
]

const FormForDoubleCollumPromotion:FC<Props> = ({choosenItem, onUpdate}) => {
    // const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
    const [active, setActive] = useState<boolean>(choosenItem.active)
    const [images, setImages] = useState<Image[]>([])

    const dispatch = useDispatch()

    const [panel, setPanel] = useState<Panel>('main');
    const [containerType, setContainerType] = useState<'full' | 'box'>((choosenItem as any)?.data?.settings?.containerType || 'box');

    // useEffect(()=>{
    //     const arrayImages = choosenAllImages.map((item: any) => {
    //         return(
    //                 {
    //                     image: item.path,
    //                     title: item.title,
    //                     link: item.link
    //                 }
    //         )
    //     })

    //     setImages((pImages)=> {
    //         return pImages.concat(arrayImages)
    //     })

    // },[choosenAllImages])

    useEffect(()=>{
        setImages(choosenItem.data?.images || [])
    }, [choosenItem])

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>, image: any) => {
        setImages((pImages)=>{
            return pImages.map((item)=>{
                const copyItem = {...item}
                if(item.image === image?.image){
                    copyItem.title = e.target.value
                }
                return copyItem
            })
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        const widgetObj = {
            name: 'two-collum-add',
            active: active,
            data : {
                images: images,
                settings: {
                    containerType: containerType
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }

        onUpdate(widgetObj)
    }
    const handleRemoveImage = (index: any) => {
        const filterImages = images.filter((_,i) => i !== index)
        setImages(filterImages)
    }

    const handleLinkData = (data: LinkData, image?: any) => {
        setImages((pImages)=>{
            return pImages.map((item)=>{
                const copyItem = {...item}
                if(item.image === image?.image){
                    copyItem.link = data
                }
                return copyItem
            })
        })
    }

    const handleImageSelector = (index?: number) => {
        dispatch(setGlobalPopupElement(
            <ImagePopUpV2
                single={index !== undefined}
                onUpdate={images => {
                    const arrayImages = images.map((item: any) => {
                        return(
                                {
                                    image: item.path,
                                    title: item.title,
                                    link: {} as LinkData
                                }
                        )
                    })

                    if (index === undefined) {
                        setImages((pImages)=> {
                            return pImages.concat(arrayImages)
                        })
                    } else {
                        setImages(prev => {
                            const newImgs = [...prev];
                            newImgs[index] = {
                                ...newImgs[index],
                                image: arrayImages[0].image,
                            }

                            return newImgs;
                        })
                    }

                    dispatch(setGlobalPopupElement(null));
                }}
                onClose={() => {
                    dispatch(setGlobalPopupElement(null));
                }}
            />
        ))
    }

  return (
    <>
    { choosenItem.name === 'two-collum-add' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>Double Column Promotion</h1>

            {/* panels switch */}
            <div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
                {panels.map((p) => (
                    <button
                        key={p.name}
                        type="button"
                        className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
                            panel === p.name
                                ? 'border-green-500 text-black'
                                : 'text-black/50 hover:text-black/70'
                        }`}
                        onClick={() => setPanel(p.name)}
                    >
                        {p.title}
                    </button>
                ))}
            </div>

            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

            {panel === 'main' ? (
                <div>
                    <ImageSortWithTitle
                        data={images}
                        setData={setImages}
                    />
                    {
                        images.map((image: any, index: number) => {
                            return(
                                <div key={image?.name} className='mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative'>
                                    {/* <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+image?.image} alt="" /> */}
                                    <ImageContainerEditButton 
                                        index={index}
                                        imageUrl={process.env.REACT_APP_IMAGE_URL + image?.image}
                                        handleImageSelector={handleImageSelector}
                                    />
                                    <input className='mb-1' value={image?.title} type="text" placeholder='title :' onChange={(e) => handleTitle(e, image)}/>
                                    <LinkForm currnetLinkData={image?.link} onUpdate={handleLinkData} selectedImage={image}/>
                                    <div className='image__popup--close-btn' onClick={()=>handleRemoveImage(index)}>
                                        <CloseIcon />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            ): panel === 'settings' ? (
                <div>
                        {/* image container full or box radio setting */}
                        <div className='flex flex-col'>
                            <div>Image container: </div>
                            <div className='[&>input]:!w-4 [&>input]:mx-2'>
                                <input type="radio" id="b-type-full" name="imageContainer" value="full" 
                                    onChange={(e) => setContainerType(e.target.value as 'full' | 'box')}
                                    checked={containerType === 'full'}
                                />
                                <label htmlFor="b-type-full">Full</label>

                                <input type="radio" id="b-type-box" name="imageContainer" value="box" 
                                    onChange={(e) => setContainerType(e.target.value as 'full' | 'box')}
                                    checked={containerType === 'box'}
                                />
                                <label htmlFor="b-type-box">Box</label>
                            </div>
                        </div>
                    </div>
            ): null}
            {/* <ImageSelector /> */}
            <ImageSelectorV2 onClick={handleImageSelector} />
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForDoubleCollumPromotion