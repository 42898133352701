import {_axios} from '../../../api'
import React, { FC, useEffect, useState } from 'react'
import { Widget } from '../../../widgetList/widgetList'
import { useDetectClickOutside } from 'react-detect-click-outside';
import CloseIcon from '../../icons/CloseIcon';
import Category from '../../Widgets/Category';
import { checkToken } from '../../../App';
import Cookies from 'js-cookie';
import { adminTokenCookieName } from '../../../utils/constants';

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

const FormForHighlightShop:FC<Props> = ({choosenItem, onUpdate}) => {
    console.log('choosenItem =', choosenItem)

    const [active, setActive] = useState(false)
    const [text, setText] = useState('')
    const [shops, setShops] = useState<any[]>([])
    const [show, setShow] = useState(true)
    const [choosenShops, setChoosenShops] = useState<any[]>([])

    useEffect(()=>{
        setActive(choosenItem.active)
        setChoosenShops(choosenItem?.data?.shops || [])
    },[choosenItem])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setText(e.target.value)
        getCategories()
    }

    const getCategories = async() => {
        checkToken()
        await _axios.get(process.env.REACT_APP_SHOPMANAGER_URL + 'shop/search/query?q=' + text, { headers: {
            "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
          }}).then(res => {
            setShops(res.data)
            setShow(true)
        })
    }

    const hideCategoryList = () => {
        setShow(false)
    }

    const handleClick = (shop: any, index: number) => {
        const _choosenShops = [...choosenShops]
        _choosenShops.push(shop)
        setChoosenShops(_choosenShops)
        setText('')
    } 

    const removeCategory = (index: number) =>{
        const filteredShops = choosenShops.filter((_,i)=> i !== index)
        setChoosenShops(filteredShops)
    }
 
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const widgetObj: any = {
            name: 'highlighted-shop',
            active: active,
            data : {
                shops: choosenShops,
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(widgetObj)
    }

    const handleRemoveId = (index :number) => {
        const filteredShops = choosenShops.filter((_,i) => i !== index)
        setChoosenShops(filteredShops)
    }

    const ref = useDetectClickOutside({ onTriggered: hideCategoryList });

  return (
    <>
    { choosenItem.name === 'highlighted-shop' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>highlighted shops</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
                    <div className=''>
                        <div className="mt-4">
                        {
                            choosenShops.map((item: any, index: number)=>{
                                return (
                                    <div key={index} className="relative h-12 bg-white shadow-lg rounded-md mb-4 flex items-center w-full">
                                        <div className='flex items-center justify-between text-sm font-light tracking-wide w-full px-3'>
                                            <div className='w-[80%] truncate'>{item.name}({item.slug})</div>
                                            <div className='w-[20%]'>ID:{item.id}</div>
                                        </div>
                                        <div onClick={()=>handleRemoveId(index)} className='absolute -top-3 -right-3 bg-white text-red-500 rounded-full shadow-lg p-1 cursor-pointer'>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
            <div>
                {/* <div>
                    {
                        choosenCategories.length > 0 && 
                        <div>
                            {
                                choosenCategories.map((choosenCategory, index) => {
                                    return(
                                        <div key={index} className="bg-white shadow-lg mb-3 px-3 rounded-md relative">
                                            <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                <div className='text-sm font-light tracking-wide truncate'>{choosenCategory.name}</div>
                                            </div>
                                            <div onClick={() => removeCategory(index)} className='absolute z-10 -top-3 -right-3 bg-white text-red-500 rounded-full p-1 shadow-lg cursor-pointer'>
                                                <CloseIcon/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div> */}
                <div>
                    <input value={text} type="text" placeholder='Search shops...' onChange={handleSearch}/>
                </div>
                <div>
                    {
                        shops.length > 0 && show &&
                        <div className='divide-y max-h-64 min-h-fit overflow-y-scroll' ref={ref}>
                            {
                                shops.map((shop, index) => {
                                    return (
                                        <div key={index} onClick={()=>handleClick(shop, index)}>
                                            <div className='flex items-center gap-4 py-2 cursor-pointer justify-between'>
                                                <div className='text-sm font-light tracking-wide truncate'>{shop?.name}</div>
                                                <div className='text-sm font-light tracking-wide truncate'>ID: {shop?.id}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForHighlightShop