import { CSSProperties, useState, useMemo, useEffect } from 'react';
import classes from './CWForm.module.css'
import { CustomFormProps } from '.';
import { useDispatch } from 'react-redux';
import { CWImageType } from '../../types/widget';
import { setGlobalPopupElement } from '../../features/popupSlice';
import ImagePopUpV2 from '../ImagePopUpV2';
import { LinkData } from '../widgetFrom/LinkForm';
import { ContentEditorV2 } from '../ContentEditor';
import SettingFormItems, { SettingItem, SettingItems } from '../SettingFormItems';

export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

const placeholderImg = '/img/image-placeholder.jpg';

type Panel = 'main' | 'settings';
const panels: {
    name: Panel;
    title: string;
} [] = [
    {
        name: 'main',
        title: 'Main',
    },
    {
        name: 'settings',
        title: 'Settings',
    },
]

type SettingNames =
'topSeoBgColor' |
'topSeoTextColor' |
'midSeoBgColor' |
'midSeoTextColor' |
'bottomSeoBgColor' |
'bottomSeoTextColor';

const settingItems: SettingItem<SettingNames> [] = [
	{
		key: 'topSeoBgColor',
		name: 'Top SEO Background Color',
		type: 'color',
	},
	{
		key: 'topSeoTextColor',
		name: 'Top SEO Text Color',
		type: 'color',
	},
	{
		key: 'midSeoBgColor',
		name: 'Middle SEO Background Color',
		type: 'color',
	},
	{
		key: 'midSeoTextColor',
		name: 'Middle SEO Text Color',
		type: 'color',
	},
	{
		key: 'bottomSeoBgColor',
		name: 'Bottom SEO Background Color',
		type: 'color',
	},
	{
		key: 'bottomSeoTextColor',
		name: 'Bottom SEO Text Color',
		type: 'color',
	},
];

type LinkKeys = 
'phone' |
'email' |
'facebook' |
'instagram' |
'twitter' |
'website' |
'gitHub' |
'linkedin' |
'youTube' |
'address';

type ContentKeys =
'top' |
'mid' |
'bottom';

type LinkItems = {
	[key in LinkKeys]?: string
}

const contactInputs: {
	name: string;
	placeholder: string;
	key: LinkKeys;
} [] = [
	{
		name: 'phone',
		placeholder: 'Phone',
		key: 'phone',
	},
	{
		name: 'email',
		placeholder: 'Email',
		key: 'email',
	},
	{
		name: 'address',
		placeholder: 'Address',
		key: 'address',
	}
]

const socialInputs: {
	name: string;
	placeholder: string;
	key: LinkKeys;
} [] = [
	{
		name: 'facebook',
		placeholder: 'Facebook',
		key: 'facebook',
	},
	{
		name: 'instagram',
		placeholder: 'Instagram',
		key: 'instagram',
	},
	{
		name: 'twitter',
		placeholder: 'Twitter',
		key: 'twitter',
	},
	{
		name: 'website',
		placeholder: 'Website',
		key: 'website',
	},
	{
		name: 'gitHub',
		placeholder: 'GitHub',
		key: 'gitHub',
	},
	{
		name: 'linkedin',
		placeholder: 'LinkedIn',
		key: 'linkedin',
	},
	{
		name: 'youTube',
		placeholder: 'YouTube',
		key: 'youTube',
	},
]

const footerContents: {
	name: string;
	placeholder?: string;
	key: ContentKeys;
} [] = [
	{
		name: 'Footer Top SEO',
		key: 'top',
	},
	{
		name: 'Footer Middle SEO',
		key: 'mid',
	},
	{
		name: 'Footer Bottom SEO',
		key: 'bottom',
	}
];

const SocialLinksForm = ({
	formItems,
	customForm = false,
	name = '',
	onSubmit = () => {},
	title,
	className,
	style,
}: CustomFormProps) => {
	const dispatch = useDispatch();
	const [imagePopupActive, setImagePopup] = useState(false);
	const [selectType, setSelectType] = useState<'signle' | 'multiple'>('multiple');

	const [links, setLinks] = useState<LinkItems>(formItems as any);
	const [image, setImage] = useState<CWImageType>((formItems as any)?.methods || {} as any);
	const [contents, setContents] = useState<{[key in ContentKeys]?: any}>((formItems as any)?.contents || {} as any);

	const [settings, setSettings] = useState<SettingItems<SettingNames>>({
		topSeoBgColor: (formItems as any)?.contents?.colors?.top?.bg || '',
		midSeoBgColor: (formItems as any)?.contents?.colors?.mid?.bg || '',
		bottomSeoBgColor: (formItems as any)?.contents?.colors?.bottom?.bg || '',

		topSeoTextColor: (formItems as any)?.contents?.colors?.top?.text || '',
		midSeoTextColor: (formItems as any)?.contents?.colors?.mid?.text || '',
		bottomSeoTextColor: (formItems as any)?.contents?.colors?.bottom?.text || '',
	});

	const [paymentMethodsActive, setPaymentMethodsActive] = useState(!!(formItems as any)?.methods);
	const [contentsActive, setContentsActive] = useState<{ [key in ContentKeys]?: boolean }>({
		top: !!(formItems as any)?.contents?.top,
		mid: !!(formItems as any)?.contents?.mid,
		bottom: !!(formItems as any)?.contents?.bottom,
	});

	const [panel, setPanel] = useState<Panel>('main');

	const linkData = {
		hyperLinkText: '',
		url: '',
		urlType: 'category',
	};

	const classNames = [classes.CWForm, className];

	const formItemsArray = objectKeys(formItems).map((key) => formItems[key]);

	const togglePupupHandler = (type: 'OPEN' | 'CLOSE') => {
		setImagePopup(type === 'OPEN');

		if (type === 'OPEN') {
			const div1 = document.querySelector('.widgetdetails__row--one') as HTMLDivElement;
			const div2 = document.querySelector('.heade__container') as HTMLDivElement;

			div1?.style.setProperty('z-index', '0');
			div2?.style.setProperty('z-index', '0');
		}
		else {
			const div1 = document.querySelector('.widgetdetails__row--one') as HTMLDivElement;
			const div2 = document.querySelector('.heade__container') as HTMLDivElement;

			div1?.style.removeProperty('z-index');
			div2?.style.removeProperty('z-index');
		}
	}

	const handleSubmit = () => {
		const data: any = {
			...links,
			methods: paymentMethodsActive ? image : undefined,
			contents: {
				...contents,
				top: contentsActive.top ? contents.top : undefined,
				mid: contentsActive.mid ? contents.mid : undefined,
				bottom: contentsActive.bottom ? contents.bottom : undefined,
				colors: {
					top: {
						bg: settings?.topSeoBgColor,
						text: settings?.topSeoTextColor,
					},
					mid: {
						bg: settings?.midSeoBgColor,
						text: settings?.midSeoTextColor,
					},
					bottom: {
						bg: settings?.bottomSeoBgColor,
						text: settings?.bottomSeoTextColor,
					}
				}
			}
		}
		
		onSubmit(data as any);
	}

	return (
		<div className={classNames.join(' ')} style={style}>
			<form className='mainSliderForm__container'
				onSubmit={e => {
					e.preventDefault();
				}}
			>
				<h1 className='title capitalize'>{title}</h1>

				{/* panels switch */}
				<div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
					{panels.map((p) => (
						<button
							key={p.name}
							type="button"
							className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
								panel === p.name
									? 'border-green-500 text-black'
									: 'text-black/50 hover:text-black/70'
							}`}
							onClick={() => setPanel(p.name)}
						>
							{p.title}
						</button>
					))}
				</div>

				{panel === 'settings' && (
					<SettingFormItems
						settingItems={settingItems}
						title="Footer SEO Settings"
						settings={settings}
						onChange={(key, value) => {
							setSettings(prev => ({
								...prev,
								[key]: value,
							}))
						}}
					/>
				)}

				{panel === 'main' && (
					<>
					<div className='mainSliderForm__inputContainer'>
						<label className='mainSliderForm__label'>Contact Info</label>
						<div className='mainSliderForm__input'>
							{contactInputs.map((item, index) => (
								<input
									key={index}
									type='text'
									placeholder={item.placeholder}
									value={links[item.key] || ''}
									className='mb-3'
									onChange={e => setLinks(prev => ({...prev, [item.key]: e.target.value}))}
								/>
							))}
						</div>
					</div>

					{/* social links input item with label */}
					<div className='mainSliderForm__inputContainer'>
						<label className='mainSliderForm__label'>Social Links</label>
						<div className='mainSliderForm__input'>
							{socialInputs.map((item, index) => (
								<input
									key={index}
									type='text'
									placeholder={item.placeholder}
									value={links[item.key] || ''}
									className='mb-3'
									onChange={e => setLinks(prev => ({...prev, [item.key]: e.target.value}))}
								/>
							))}
						</div>
					</div>

					{/* footer payment methods */}
					<div className='mainSliderForm__inputContainer'>
						<div className="flex gap-2 items-center">
							<input
								id='pm-w-342'
								type='checkbox'
								className='!w-auto'
								checked={paymentMethodsActive}
								onChange={e => setPaymentMethodsActive(e.target.checked)}
							/>
							<label htmlFor='pm-w-342' className='mainSliderForm__label'>Payment Methods</label>
						</div>

						{paymentMethodsActive && (
							<>
								<div className="image__container mb-2">
									<img className='rounded-md mt-2' 
										src={image?.url ? process.env.REACT_APP_IMAGE_URL + image?.url : placeholderImg} 
										alt={image?.name || name} 
									/>
								</div>

								<button onClick={(e) => {
									e.preventDefault();

									dispatch(setGlobalPopupElement((
										<ImagePopUpV2
											single={true}
											onUpdate={images => {
												const arrayImages = images.map((item: any) => {
													return(
															{
																image: item.path,
																title: item.title,
																link: {} as LinkData
															}
													)
												})

												const item = arrayImages[0];

												setImage({
													url: item.image,
													name: image?.name || item.title,
													link: image?.link || {} as LinkData,
												})

												dispatch(setGlobalPopupElement(null));
											}}
											onClose={() => {
												dispatch(setGlobalPopupElement(null));
											}}
										/>
									)))

								}} className='imageSelector__btn mb-2'>choose image</button>
							</>
						)}
						
					</div>

					<div className='mainSliderForm__inputContainer'>
						{footerContents.map((item, index) => {
							const id = `content-w342-${index}`;
							return (
								<div key={index}>
									<div className="flex gap-2 items-center">
										<input
											id={id}
											type='checkbox'
											className='!w-auto'
											checked={contentsActive[item.key]}
											onChange={e => setContentsActive(prev => ({...prev, [item.key]: e.target.checked}))}
										/>
										<label htmlFor={id} className='mainSliderForm__label'>{item.name}</label>
									</div>

									{contentsActive[item.key] && (
										<button type="button" onClick={() => {
											dispatch(setGlobalPopupElement(
												<ContentEditorV2 
													content={contents[item.key] || ''}
													onSubmit={(data) => {
														setContents(prev => {
															if (!prev) return {
																[item.key]: data
															}
		
															return {
																...prev,
																[item.key]: data
															}
														})
													}}
													onClose={() => {
														dispatch(setGlobalPopupElement(null));
													}}
												/>
											))
										}} 
										className='mainSliderForm__btn !bg-slate-500/90 hover:!bg-slate-500'>Edit Content</button>
									)}
								</div>
							)
						})}
					</div>
					</>
				)}

				<div>
					<button onClick={e => {
						e.preventDefault();
						handleSubmit();
					}} className='mainSliderForm__btn'>save</button>
				</div>
			</form>
		</div>
	)
}

export default SocialLinksForm