import { useDispatch } from "react-redux";
import PopupWrapper from "./PopupWrapper";
import SortList from "./ListSort";
import { setGlobalPopupElement } from "../features/popupSlice";
import { Image } from "../widgetList/widgetList";

const ImageSortWithTitle = ({
    data,
    setData
}: {
    data: Image [],
    setData: (data: Image[]) => void
}) => {
    const dispatch = useDispatch();

    return (
        <div
            className='flex items-center justify-between'
        >
            <p>Images:</p>
            <button
                type='button'
                onClick={() => {
                    dispatch(setGlobalPopupElement(
                        <PopupWrapper>
                            <SortList
                                data={data}
                                renderElement={(item) => (
                                    <div
                                        className='flex jusbetween items-center gap-2'
                                    >
                                        <img 
                                            className='rounded-md mb-1 object-contain'
                                            src={process.env.REACT_APP_IMAGE_URL+item?.image}
                                            alt=""
                                            width={50}
                                            height={50}
                                        />

                                        <p
                                            className='text-sm tracking-wide text-center border-b-2'
                                        >{item?.link?.url}</p>
                                    </div>
                                )}
                                updateData={(data) => {
                                    setData(data);
                                    dispatch(setGlobalPopupElement(null));
                                }}
                            />
                        </PopupWrapper>
                    ))
                }}
                className='text-sm tracking-wide text-center border-b-2 focus:outline-none text-black/50 hover:text-black/70'
            >SORT Images</button>
        </div>
    )
}

export default ImageSortWithTitle