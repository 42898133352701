import { CSSProperties } from 'react';
import FormInputItem, { InputTypes } from './FormInputItem';

export type SettingItems<T extends string> = {
	[key in T]?: string
}

export interface SettingItem<T> {
	name: string;
	placeholder?: string;
	type: InputTypes;
	options?: {
		value: string;
		name: string;
	} []
	key: T;
}

interface Props<T extends string> {
    title?: string;
    settings?: SettingItems<T>;
    settingItems: SettingItem<T> [];
    onChange: (key: string, value: string) => void;
    className?: string;
    style?: CSSProperties;
}

const SettingFormItems = <T extends string> ({
	settingItems,
    settings = {},
    onChange,
	title,
	className,
	style,
}: Props<T>) => {

	const classNames = [className];

	return (
		<div className={classNames.join(' ')} style={style}>
			<div className='mainSliderForm__inputContainer'>
                {title && <>
                    <h5 className='mainSliderForm__label'>{title}</h5>
                    <hr />
                </>}
                <div className='mainSliderForm__input'>
                    {settingItems.map((item, index) => (
                        <FormInputItem
                            key={index}
                            label={item.name}
                            value={settings[item.key] as any}
                            type={item.type}
                            options={item.options}
                            onChange={value => {
                                const isNumber = item.type === 'number';
                                const _value = isNumber ? +value : value;
                                onChange(item.key, _value);
                            }}
                        />
                    ))}
                </div>
            </div>
		</div>
	)
}

export default SettingFormItems