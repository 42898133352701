// import loaderGif from './Splash.gif'
// import loaderLg from './splash-lg.png';
import { logos } from '../../utils/constants';
import loaderimg from './mm.png';

import classes from './style.module.css';

export const PageLoader = ({
  type = 'default',
}: {
  type?: 'default' | 'full';
}) => {

  return (
    <div className={`page-loader ${type === 'full' ? '!bg-black/20': '!bg-black/20'}`}>

      {/* <Image 
        src={loaderGif}
        alt="loader"
        objectFit='contain'
        className='image'
        unoptimized={true}
      /> */}

      <div className="w-32 h-32 md:w-44 md:h-44 relative rounded-full flex justify-center items-center bg-black/5 p-6">
        <div className={classes.borderOuter}></div>
        <div className={classes.borderInner}></div>
          {/* {type === 'full' && (
          )} */}
          <img 
            src={logos.logoWhite}
            alt="loader"
            // layout="fill"
            className='rounded-[1.4rem]'
          />
      </div>
    </div>
)
}