import Cookies from 'js-cookie';
import React, { FC, useEffect, useState, UIEvent } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch } from 'react-redux';
import { _axios } from '../../../api';
import { checkToken } from '../../../App';
import { setGlobalPopupElement } from '../../../features/popupSlice';
import { Category, CategoryPagination, Page } from '../../../pages/MenuDetails'
import { adminTokenCookieName } from '../../../utils/constants';
import { LogicalObj } from '../../../widgetList/widgetList';
import CategorySearchV2 from '../../CategorySearchV2';

export interface LinkData {
    hyperLinkText: string;
    url: string;
    urlType: string;
}

interface Props {
    currnetLinkData: LinkData
    onUpdate: (data: LinkData, image?: any) => void
    selectedImage?: any
}

const LinkForm:FC<Props> = ({currnetLinkData, onUpdate, selectedImage}) => {


    const [hyperLinkText, setHyperLinkText] = useState('')
    const [url, setURL] = useState('')
    const [urlType, setURLType] = useState<string>('custom')

    const [categoryText, setCategoryText] = useState<string>('')
    const [pageText, setPageText] = useState<string>('')
    const [categories, setCategories] = useState<Category[]>([])
    const [pages, setPages] = useState<Page[]>([])
    const [campaignText, setcampaignText] = useState<string>('')
    const [campaigns, setCampaigns] = useState<LogicalObj []>([])
    const [image, setImage] = useState<any>(null)
    const [categoryPagination, setCategoryPagination] = useState<CategoryPagination>(
      {
      total: 0,
      lastPage: 1,
      currentPage: 1
    })
    const [pagePagination, setPagePagination] = useState<CategoryPagination>(
      {
      total: 0,
      lastPage: 1,
      currentPage: 1
    })
    const [campaignPagination, setcampaignPagination] = useState<CategoryPagination>(
      {
      total: 0,
      lastPage: 1,
      currentPage: 1
    })
    const [showCategory, setShowCategory] = useState(true)
    const [showPage, setShowPage] = useState(true)
    const [showCampaigns, setShowCampaigns] = useState(true)
  
    const categorySuggestion = useDetectClickOutside({ onTriggered: () => setShowCategory(false) });
    const pageSuggestion = useDetectClickOutside({ onTriggered: () => setShowPage(false) });

    // const categoryPopup = useCategoryPopup();
    const dispatch = useDispatch();

    useEffect(()=>{
        setURL(currnetLinkData?.url || '')
        setHyperLinkText(currnetLinkData?.hyperLinkText || '')
        setURLType(currnetLinkData?.urlType || 'custom')
        if(selectedImage) {
          setImage(selectedImage)
        }
    },[currnetLinkData])


    useEffect(()=>{
        const linkData: LinkData = {
            hyperLinkText,
            url,
            urlType
        }
        onUpdate(linkData, image)
    },[hyperLinkText, url, urlType])

    // useEffect(() => {
    //   if (categoryPopup.category) {
    //     setURL(categoryPopup.category.slug)
    //   }
    // }, [categoryPopup.category]);

    const handleCategoryPopupOpen = () => {
      dispatch(setGlobalPopupElement((
        <CategorySearchV2 
          allClickable={true}
          onSelect={(category) => {
            setURL(category.slug)
          }}
        />
      )))
    }

    const handleHeyperLinkText = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHyperLinkText(e.target.value)
    }

    const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        setURL(e.target.value)
    }

    const handleCategorySearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const target: string = e.target.value
        setCategoryText(target)
        setCategoryPagination({
          total: 0,
          lastPage: 1,
          currentPage: 1
        })
        target.length > 0 ? getCategories(target) : setShowCategory(false)
      }
    
      const handlePageSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const target: string = e.target.value
        setPageText(target)
        setPagePagination({
          total: 0,
          lastPage: 1,
          currentPage: 1
        })
        target.length > 0 ? getPages(target) : setShowPage(false)
      }

      const handlecampaignSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const target: string = e.target.value
        setcampaignText(target)
        setcampaignPagination({
          total: 0,
          lastPage: 1,
          currentPage: 1
        })
        target.length > 0 ? getcampaigns(target) : setShowPage(false)
      }
    
      const getCategories = async (searchText: string) => {
        checkToken()
        await _axios.get(process.env.REACT_APP_BASE_URL + `catalog/category?page=${categoryPagination.currentPage}&perPage=10&s=${searchText}`, {
          headers: {
              'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
          }
      }).then(res => {
          if (res.data.pagination.currentPage === 1) {
            let updatedPagination = {...categoryPagination, total: res.data.pagination.total, lastPage: res.data.pagination.lastPage, currentPage: res.data.pagination.currentPage}
            setCategoryPagination(updatedPagination)
            setCategories(res.data.data)
            } else {
              const updatedCategories= [...categories].concat(res.data.data)
              setCategories(updatedCategories)
            }
          setShowCategory(true)
        })
      }
    
      const getPages = async (searchText: string) => {
        checkToken()
        await _axios.get(process.env.REACT_APP_BUILDER_URL + `page?currentPage=${pagePagination.currentPage}&perPage=10&search=${searchText}`, {
          headers: {
              'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
          }
      }).then(res => {
          if (res.data.currentPage === 1) {
            let updatedPagination = {...pagePagination, total: res.data.total, lastPage: res.data.lastPage, currentPage: res.data.currentPage}
            setPagePagination(updatedPagination)
            setPages(res.data.data)
            } else {
              const updatedPages= [...pages].concat(res.data.data)
              setPages(updatedPages)
            }
          setShowPage(true)
        })
      }

      const getcampaigns = async (searchText: string) => {
        checkToken()
      //   await _axios.get(process.env.REACT_APP_CAMPAIGN_URL + `pagebuilder/page?currentPage=${pagePagination.currentPage}&perPage=10&search=${searchText}`, {
      //     headers: {
      //         'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
      //     }
      // }).then(res => {
      //     if (res.data.currentPage === 1) {
      //       let updatedPagination = {...pagePagination, total: res.data.total, lastPage: res.data.lastPage, currentPage: res.data.currentPage}
      //       setPagePagination(updatedPagination)
      //       setPages(res.data.data)
      //       } else {
      //         const updatedPages= [...pages].concat(res.data.data)
      //         setPages(updatedPages)
      //       }
      //     setShowPage(true)
      //   })
        await _axios.get(process.env.REACT_APP_CAMPAIGN_URL + 'campaigns?s=' + searchText, { headers: {
          "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
        }}).then(res=>{
          const data = res.data.data
          setCampaigns(data)
          setShowCampaigns(true)
        })
      }
    
      const handleCategoryScroll = (e: UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        if ((categoryPagination.currentPage < categoryPagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
          getCategories(categoryText)
          let updatePagination = {...categoryPagination, currentPage: categoryPagination.currentPage + 1}
          setCategoryPagination(updatePagination)
        }
      }
    
      const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        if ((pagePagination.currentPage < pagePagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
          getPages(pageText)
          let updatePagination = {...pagePagination, currentPage: pagePagination.currentPage + 1}
          setPagePagination(updatePagination)
        }
      }
    
      const handleCategoryClick = (category: Category) => {
        setCategoryText('')
        setURL(category.slug)
        const newItem = {
          url: category.slug,
          urlType: 'category'
        }
        setShowCategory(false)        
    } 
    
      const handlePageClick = (page: Page) => {
        const pageSlug = page.name?.toLowerCase().replace(/\s+/g, '_');
        
        setPageText('')
        setURL(pageSlug)
        const newItem = {
        url: sluggify(page.name),
        urlType: 'page'
        }
        setShowPage(false)
      }
      const handlecampaignClick = (campaign: any) => {
        console.log('campaign =>', campaign)
        // const campaignSlug = sluggify(campaign.name)
        setcampaignText('')
        setURL(campaign.slug)
        const newItem = {
          url: campaign.slug,
          urlType: 'page'
        }
        setShowCampaigns(false)
      }


      const sluggify = (toConvert:string): string => {
        return toConvert.normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
        .toLowerCase()
        .replace(/\s+/g, '_') //spaces to dashes
        .replace(/[^\w\-]+/g, '') //remove non-words
        .replace(/\-\-+/g, '-') //collapse multiple dashes
        .replace(/\_\_+/g, '_') //collapse multiple dashes
        .replace(/^-+/, '') //trim starting dash
        .replace(/-+$/, '') //trim ending dash,
      }

      const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
      }

    return (
        <>
        {
            <form onSubmit={handleSubmit} className='mainSliderForm__container'>
                <div>
                    <div className='border-b pb-4'>
                    <div>
                        <div className='mt-2'>
                            <div>Link Type: </div>
                            <div className='flex flex-col items-start gap-2 mb-4 mt-2'>
                                <div onClick={()=> {
                                    setURLType('category')
                                    setURL('')
                                    }
                                } className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${urlType === 'category' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Category</div>
                                </div>
                                <div onClick={()=> {
                                    setURLType('page')
                                    setURL('')
                                    }
                                } className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${urlType === 'page' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Page</div>
                                </div>
                                <div onClick={()=> {
                                    setURLType('custom')
                                    setURL('')
                                    }
                                } className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${urlType === 'custom' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Custom</div>
                                </div>
                                <div onClick={()=> {
                                    setURLType('campaign')
                                    setURL('')
                                    }
                                } className='flex items-center gap-2 cursor-pointer'>
                                    <div className={`w-5 h-5 rounded-md ${urlType === 'campaign' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                    <div>Campaign</div>
                                </div>
                            </div>
                        </div>
                    <div>
                    
                    { urlType === 'category' ?
                    <div>
                        <div className="tracking-wide mb-2">
                            Category:
                        </div>
                        <div className='mb-4'>
                            {/* <input type="text" placeholder='Search Categories...' value={categoryText} onChange={handleCategorySearch} /> */}
                            <input className='cursor-pointer' onClick={() => {
                              // categoryPopup.open();
                              handleCategoryPopupOpen();
                            }} type="text" placeholder='Choose category...' readOnly />
                        </div>
                        <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                            {categories.length > 0 && showCategory &&
                                <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar' ref={ categorySuggestion} onScroll={handleCategoryScroll}>
                                    {categories.map((category, index) => {
                                        return (
                                            <div key={index} onClick={() => handleCategoryClick(category)}>
                                                <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                    <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                    <div>{category.id}</div>
                                                    <div className='text-sm font-light tracking-wide truncate'>{category.name}</div>
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                    : urlType === 'page' ?
                    <div>
                        <div className="tracking-wide mb-2">
                        Page: 
                        </div>
                        <div className='mb-4'>
                        <input type="text" placeholder='Search Pages...' value={pageText} onChange={handlePageSearch}/>
                        </div>
                        <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                            {(pages.length > 0 && showPage) &&
                            <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar' ref={pageSuggestion} onScroll={handlePageScroll}>
                                {
                                    pages.map((page, index) => {
                                        return (
                                            <div key={index} onClick={()=>handlePageClick(page)}>
                                                <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                    <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                    <div className='text-sm font-light tracking-wide truncate'>{page.name}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                  </div>
                  : urlType === 'campaign' ?
                    <div>
                        <div className="tracking-wide mb-2">
                        campaign: 
                        </div>
                        <div className='mb-4'>
                        <input type="text" placeholder='Search campaign...' value={campaignText} onChange={handlecampaignSearch}/>
                        </div>
                        <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                            {(campaigns.length > 0 && showCampaigns) &&
                            <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar'>
                                {
                                    campaigns.map((campaign, index) => {
                                        return (
                                            <div key={index} onClick={()=>handlecampaignClick(campaign)}>
                                                <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                    <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                    <div className='text-sm font-light tracking-wide truncate'>{campaign.name}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                  </div>
                 : null}
                </div>
                    <div>URL: </div>
                    <input type="text" value={url} placeholder='URL :' onChange={(e)=> handleUrl(e)} disabled={ urlType !== 'custom'} className={ urlType !== 'custom' ? 'text-gray-500 mb-4' : 'text-black mb-4'}/>
                    <div>Hyperlink Text: </div>
                    <input type="text" value={hyperLinkText} placeholder='Hyperlink Text :' onChange={(e)=> handleHeyperLinkText(e)}/>
                    </div>
                </div>
                </div>
            </form>
        }
        </>
    )
}

export const LinkFormV2:FC<Props> = ({currnetLinkData, onUpdate, selectedImage}) => {


  const [hyperLinkText, setHyperLinkText] = useState('')
  const [url, setURL] = useState('')
  const [urlType, setURLType] = useState<string>('custom')

  const [categoryText, setCategoryText] = useState<string>('')
  const [pageText, setPageText] = useState<string>('')
  const [categories, setCategories] = useState<Category[]>([])
  const [pages, setPages] = useState<Page[]>([])
  const [campaignText, setcampaignText] = useState<string>('')
  const [campaigns, setCampaigns] = useState<LogicalObj []>([])
  const [image, setImage] = useState<any>(null)
  const [categoryPagination, setCategoryPagination] = useState<CategoryPagination>(
    {
    total: 0,
    lastPage: 1,
    currentPage: 1
  })
  const [pagePagination, setPagePagination] = useState<CategoryPagination>(
    {
    total: 0,
    lastPage: 1,
    currentPage: 1
  })
  const [campaignPagination, setcampaignPagination] = useState<CategoryPagination>(
    {
    total: 0,
    lastPage: 1,
    currentPage: 1
  })
  const [showCategory, setShowCategory] = useState(true)
  const [showPage, setShowPage] = useState(true)
  const [showCampaigns, setShowCampaigns] = useState(true)

  const categorySuggestion = useDetectClickOutside({ onTriggered: () => setShowCategory(false) });
  const pageSuggestion = useDetectClickOutside({ onTriggered: () => setShowPage(false) });

  // const categoryPopup = useCategoryPopup();

  const dispatch = useDispatch()

  useEffect(()=>{
      setURL(currnetLinkData?.url || '')
      setHyperLinkText(currnetLinkData?.hyperLinkText || '')
      setURLType(currnetLinkData?.urlType || 'custom')
      if(selectedImage) {
        setImage(selectedImage)
      }
  },[])


  useEffect(()=>{
      const linkData: LinkData = {
          hyperLinkText,
          url,
          urlType
      }
      onUpdate(linkData, image)
  },[hyperLinkText, url, urlType])

  // useEffect(() => {
  //   if (categoryPopup.category) {
  //     setURL(categoryPopup.category.slug)
  //   }
  // }, [categoryPopup.category]);

  const handleCategoryPopupOpen = () => {
    dispatch(setGlobalPopupElement((
      <CategorySearchV2 
        onSelect={(category) => {
          setURL(category.slug)
        }}
      />
    )))
  }

  const handleHeyperLinkText = (e: React.ChangeEvent<HTMLInputElement>) => {
      setHyperLinkText(e.target.value)
  }

  const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
      setURL(e.target.value)
  }

  const handleCategorySearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
      const target: string = e.target.value
      setCategoryText(target)
      setCategoryPagination({
        total: 0,
        lastPage: 1,
        currentPage: 1
      })
      target.length > 0 ? getCategories(target) : setShowCategory(false)
    }
  
    const handlePageSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
      const target: string = e.target.value
      setPageText(target)
      setPagePagination({
        total: 0,
        lastPage: 1,
        currentPage: 1
      })
      target.length > 0 ? getPages(target) : setShowPage(false)
    }

    const handlecampaignSearch = (e: React.ChangeEvent<HTMLInputElement>) =>{
      const target: string = e.target.value
      setcampaignText(target)
      setcampaignPagination({
        total: 0,
        lastPage: 1,
        currentPage: 1
      })
      target.length > 0 ? getcampaigns(target) : setShowPage(false)
    }
  
    const getCategories = async (searchText: string) => {
      checkToken()
      await _axios.get(process.env.REACT_APP_BASE_URL + `catalog/category?page=${categoryPagination.currentPage}&perPage=10&s=${searchText}`, {
        headers: {
            'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
        }
    }).then(res => {
        if (res.data.pagination.currentPage === 1) {
          let updatedPagination = {...categoryPagination, total: res.data.pagination.total, lastPage: res.data.pagination.lastPage, currentPage: res.data.pagination.currentPage}
          setCategoryPagination(updatedPagination)
          setCategories(res.data.data)
          } else {
            const updatedCategories= [...categories].concat(res.data.data)
            setCategories(updatedCategories)
          }
        setShowCategory(true)
      })
    }
  
    const getPages = async (searchText: string) => {
      checkToken()
      await _axios.get(process.env.REACT_APP_BUILDER_URL + `page?currentPage=${pagePagination.currentPage}&perPage=10&search=${searchText}`, {
        headers: {
            'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
        }
    }).then(res => {
        if (res.data.currentPage === 1) {
          let updatedPagination = {...pagePagination, total: res.data.total, lastPage: res.data.lastPage, currentPage: res.data.currentPage}
          setPagePagination(updatedPagination)
          setPages(res.data.data)
          } else {
            const updatedPages= [...pages].concat(res.data.data)
            setPages(updatedPages)
          }
        setShowPage(true)
      })
    }

    const getcampaigns = async (searchText: string) => {
      checkToken()
    //   await _axios.get(process.env.REACT_APP_CAMPAIGN_URL + `pagebuilder/page?currentPage=${pagePagination.currentPage}&perPage=10&search=${searchText}`, {
    //     headers: {
    //         'Authorization': `Bearer ${Cookies.get(adminTokenCookieName)}` 
    //     }
    // }).then(res => {
    //     if (res.data.currentPage === 1) {
    //       let updatedPagination = {...pagePagination, total: res.data.total, lastPage: res.data.lastPage, currentPage: res.data.currentPage}
    //       setPagePagination(updatedPagination)
    //       setPages(res.data.data)
    //       } else {
    //         const updatedPages= [...pages].concat(res.data.data)
    //         setPages(updatedPages)
    //       }
    //     setShowPage(true)
    //   })
      await _axios.get(process.env.REACT_APP_CAMPAIGN_URL + 'campaigns?s=' + searchText, { headers: {
        "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
      }}).then(res=>{
        const data = res.data.data
        setCampaigns(data)
        setShowCampaigns(true)
      })
    }
  
    const handleCategoryScroll = (e: UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement
      if ((categoryPagination.currentPage < categoryPagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
        getCategories(categoryText)
        let updatePagination = {...categoryPagination, currentPage: categoryPagination.currentPage + 1}
        setCategoryPagination(updatePagination)
      }
    }
  
    const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement
      if ((pagePagination.currentPage < pagePagination.lastPage) && (target.scrollTop + target.clientHeight >= target.scrollHeight)) {
        getPages(pageText)
        let updatePagination = {...pagePagination, currentPage: pagePagination.currentPage + 1}
        setPagePagination(updatePagination)
      }
    }
  
    const handleCategoryClick = (category: Category) => {
      setCategoryText('')
      setURL(category.slug)
      const newItem = {
        url: category.slug,
        urlType: 'category'
      }
      setShowCategory(false)        
  } 
  
    const handlePageClick = (page: Page) => {
      const pageSlug = page.name?.toLowerCase().replace(/\s+/g, '_');
      setPageText('')
      setURL(pageSlug)
      const newItem = {
      url: sluggify(page.name),
      urlType: 'page'
      }
      setShowPage(false)
    }
    const handlecampaignClick = (campaign: any) => {
      console.log('campaign =>', campaign)
      // const campaignSlug = sluggify(campaign.name)
      setcampaignText('')
      setURL(campaign.slug)
      const newItem = {
        url: campaign.slug,
        urlType: 'page'
      }
      setShowCampaigns(false)
    }


    const sluggify = (toConvert:string): string => {
      return toConvert.normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '_') //spaces to dashes
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/\_\_+/g, '_') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, '') //trim ending dash,
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
    }

  return (
      <>
      {
          <form onSubmit={handleSubmit} className='mainSliderForm__container'>
              <div>
                  <div className='border-b pb-4'>
                  <div>
                      <div className='mt-2'>
                          <div>Link Type: </div>
                          <div className='flex flex-col items-start gap-2 mb-4 mt-2'>
                              <div onClick={()=> {
                                  setURLType('category')
                                  setURL('')
                                  }
                              } className='flex items-center gap-2 cursor-pointer'>
                                  <div className={`w-5 h-5 rounded-md ${urlType === 'category' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                  <div>Category</div>
                              </div>
                              <div onClick={()=> {
                                  setURLType('page')
                                  setURL('')
                                  }
                              } className='flex items-center gap-2 cursor-pointer'>
                                  <div className={`w-5 h-5 rounded-md ${urlType === 'page' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                  <div>Page</div>
                              </div>
                              <div onClick={()=> {
                                  setURLType('custom')
                                  setURL('')
                                  }
                              } className='flex items-center gap-2 cursor-pointer'>
                                  <div className={`w-5 h-5 rounded-md ${urlType === 'custom' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                  <div>Custom</div>
                              </div>
                              <div onClick={()=> {
                                  setURLType('campaign')
                                  setURL('')
                                  }
                              } className='flex items-center gap-2 cursor-pointer'>
                                  <div className={`w-5 h-5 rounded-md ${urlType === 'campaign' ? 'bg-green-500 shadow' : 'bg-gray-300 shadow-inner'}`}></div>
                                  <div>Campaign</div>
                              </div>
                          </div>
                      </div>
                  <div>
                  
                  { urlType === 'category' ?
                  <div>
                      <div className="tracking-wide mb-2">
                          Category:
                      </div>
                      <div className='mb-4'>
                          {/* <input type="text" placeholder='Search Categories...' value={categoryText} onChange={handleCategorySearch} /> */}
                          <input className='cursor-pointer' onClick={() => {

                            // categoryPopup.open();
                            handleCategoryPopupOpen();
                            
                          }} type="text" placeholder='Choose category...' readOnly />
                      </div>
                      <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                          {categories.length > 0 && showCategory &&
                              <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar' ref={ categorySuggestion} onScroll={handleCategoryScroll}>
                                  {categories.map((category, index) => {
                                      return (
                                          <div key={index} onClick={() => handleCategoryClick(category)}>
                                              <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                  <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                  <div>{category.id}</div>
                                                  <div className='text-sm font-light tracking-wide truncate'>{category.name}</div>
                                              </div>
                                          </div>
                                      )}
                                  )}
                              </div>
                          }
                      </div>
                  </div>
                  : urlType === 'page' ?
                  <div>
                      <div className="tracking-wide mb-2">
                      Page: 
                      </div>
                      <div className='mb-4'>
                      <input type="text" placeholder='Search Pages...' value={pageText} onChange={handlePageSearch}/>
                      </div>
                      <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                          {(pages.length > 0 && showPage) &&
                          <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar' ref={pageSuggestion} onScroll={handlePageScroll}>
                              {
                                  pages.map((page, index) => {
                                      return (
                                          <div key={index} onClick={()=>handlePageClick(page)}>
                                              <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                  <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                  <div className='text-sm font-light tracking-wide truncate'>{page.name}</div>
                                              </div>
                                          </div>
                                      )
                                  })
                              }
                          </div>
                      }
                  </div>
                </div>
                : urlType === 'campaign' ?
                  <div>
                      <div className="tracking-wide mb-2">
                      campaign: 
                      </div>
                      <div className='mb-4'>
                      <input type="text" placeholder='Search campaign...' value={campaignText} onChange={handlecampaignSearch}/>
                      </div>
                      <div className='bg-gray-100 drop-shadow-md shadow-inner mb-4 rounded'>
                          {(campaigns.length > 0 && showCampaigns) &&
                          <div className='pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar'>
                              {
                                  campaigns.map((campaign, index) => {
                                      return (
                                          <div key={index} onClick={()=>handlecampaignClick(campaign)}>
                                              <div className='flex items-center gap-4 py-2 cursor-pointer'>
                                                  <img className='w-10 h-10 rounded-full object-cover object-center' src="https://www.kalypsofarms.com/wp-content/uploads/2019/08/dummy.png" alt="" />
                                                  <div className='text-sm font-light tracking-wide truncate'>{campaign.name}</div>
                                              </div>
                                          </div>
                                      )
                                  })
                              }
                          </div>
                      }
                  </div>
                </div>
               : null}
              </div>
                  <div>URL: </div>
                  <input type="text" value={url} placeholder='URL :' onChange={(e)=> handleUrl(e)} disabled={ urlType !== 'custom'} className={ urlType !== 'custom' ? 'text-gray-500 mb-4' : 'text-black mb-4'}/>
                  <div>Hyperlink Text: </div>
                  <input type="text" value={hyperLinkText} placeholder='Hyperlink Text :' onChange={(e)=> handleHeyperLinkText(e)}/>
                  </div>
              </div>
              </div>
          </form>
      }
      </>
  )
}

export default LinkForm