import React from 'react'
import './indemand.css'

const InDemand:React.FC = () => {
  return (
    <div className='indemand__container'>
        <div className="indemand__wrapper">
            <img src="/img/widgets/In_Demand.png" alt=''/>
        </div>
    </div>
  )
}

export default InDemand