import React, { FC, useEffect, useState } from 'react'
import { SubMenu } from '../../features/addMenu'
import Swal from 'sweetalert2';
import { NestingParams } from '../../pages/MenuDetails';
import { checkToken } from '../../App';

interface Props {
    choosenItem: SubMenu
    chosenIndex: string
    subMenus: SubMenu[]
    onUpdate: (array: SubMenu[], deleteIndices: number[], insertIndicies: number[], toBeInserted: SubMenu, optionalParams:NestingParams) => void
}

const MenuForm:FC<Props> = ({choosenItem, chosenIndex, subMenus, onUpdate}) => {
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [numberIndex, setNumberIndex] = useState<number[]>([])

    useEffect(()=>{
        setName(choosenItem.name)
        setUrl(choosenItem.url)
        setNumberIndex(convertToIntegerIndex(chosenIndex))
    },[choosenItem])

    const convertToIntegerIndex =(indexes:string) => {
        return indexes.length > 1 ? indexes.split('_').map(str => {
          return Number(str);
        }) : [Number(indexes)]
    }
    const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        checkToken()
        e.preventDefault()
        const updatedData: SubMenu = {
            name,
            url,
            submenu: choosenItem.submenu,
            type: choosenItem.type
        }
        onUpdate(JSON.parse(JSON.stringify((subMenus))), numberIndex, numberIndex, updatedData, {type: 'deep', updateOnly:true})
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 2500
          });
          
          Toast.fire({
            icon: 'success',
            title: 'Updated and saved as draft'
          })
    }
  return (
    <>
    { choosenItem.name &&
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <div>
                <div>
                    <div className='capitalize tracking-wide'>Name :</div>
                    <input value={name} onChange={handleName} type="text" placeholder='Type here....'/>
                </div>
                <div className='mt-3'>
                    <div className='capitalize tracking-wide'>{choosenItem.type !== 'custom' ? 'Slug': 'Url'} :</div>
                    <input value={url} onChange={handleUrl} type="text" placeholder='Type here....' 
                    disabled={(choosenItem.type !== 'custom')} className={choosenItem.type?.length as number > 0 ? 'text-gray-500' : ''}/>
                </div>
            </div>
            <div>
                <button className={name === choosenItem.name && url === choosenItem.url ? 'dissabledSliderForm__btn' : 'mainSliderForm__btn'} disabled={name === choosenItem.name && url === choosenItem.url}>Update</button>
            </div>
        </form>
    }
    </>
  )
}

export default MenuForm