import { createSlice } from '@reduxjs/toolkit';

export interface ShowImagesPopUp {
    value: boolean,
    image: any
}

const initialState: ShowImagesPopUp = {
    value: false,
    image: {}
}

export const imagesDetailsSlice = createSlice({
    name: 'imageDetails',
    initialState,
    reducers: {
        openImagesDetails: (state) => {
            state.value = true
        },
        closeImagesDetails: (state) => {
            state.value = false
        },
        getImageDetails: (state, action) => {
            state.image = action.payload
        }
    }
})

export const {openImagesDetails, closeImagesDetails, getImageDetails} = imagesDetailsSlice.actions

export default imagesDetailsSlice.reducer