
import {useState} from 'react';
import {SortEndHandler, SortableContainer, SortableElement} from 'react-sortable-hoc';

function arrayMove<T>(array: readonly T[], from: number, to: number) {
    const slicedArray = array.slice();
    slicedArray.splice(
      to < 0 ? array.length + to : to,
      0,
      slicedArray.splice(from, 1)[0]
    );

    return slicedArray;
  }

const SortableItem = SortableElement<any>(({value, remove}: { value: JSX.Element, remove: ()=>void }) => (
  <div
  className='border border-gray-300 bg-white rounded-md p-2 cursor-pointer !z-[5000] flex gap-2 justify-between items-center'
  >
    <p
        tabIndex={0}
    >
        {value}
    </p>

    {/* <IconButton
        size='small'
        color='error'
        onClick={remove}
    >
        <Close />
    </IconButton> */}
  </div>
));

const SortableList = SortableContainer<any>(<T extends object> ({
    items,
    renderLabel,
    onRemove
}: {
    items: T[];
    readonly renderLabel: (item: T) => JSX.Element;
    onRemove: (index: number) => void;
}) => {
  return (
    <div
        className='flex flex-col gap-2'
    >
      {items.map((value, index) => (
        <SortableItem key={`item-${(value as any)?.id || index}`} index={index} 
            value={renderLabel(value)}
            remove={() => onRemove(index)}
        />
      ))}
    </div>
  );
});

const SortList = <T extends any> ({
    data,
    renderElement,
    updateData
}: {
    data: T[];
    renderElement: (item: T) => JSX.Element;
    updateData: (data: T[]) => void;
}) => {

    const [items, setItems] = useState(data);
    const onSortEnd: SortEndHandler = ({oldIndex, newIndex}) => {
        setItems(items => arrayMove(items, oldIndex, newIndex));
    }

    return (
        <div
            className='p-4 min-w-[300px] max-h-[80vh] overflow-y-auto'
        >
            <SortableList 
                items={items}
                onRemove={(index: number) => {
                    setItems(items => {
                        const newItems = items.slice();
                        newItems.splice(index, 1);

                        return newItems;
                    })
                }}
                renderLabel={renderElement as any} onSortEnd={onSortEnd} />

            <hr 
                className='mt-4'
            />

            <button
                className='bg-blue-500 text-white rounded-md px-4 py-2 mt-4'
                onClick={() => {
                    updateData(items)
                }}
            >Save</button>
        </div>
    )
}

export default SortList;