import React from 'react'
import './dashboardpage.css'

const DashBoardPage = () => {
  return (
    <div className='dashboard__container'>
      <div className='dashboard__content'>
        <h1>👋 welcome!</h1>
        <p>let's customize pages</p>
      </div>
    </div>
  )
}

export default DashBoardPage