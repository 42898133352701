import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { updateWidgetType } from '../../features/widgetFilter/widgetFilter'
import './widgetFilter.css'

const WidgetFilter:FC = () => {
    const type: string = useSelector((state: RootState) => state.widgetTypeFilter.type)
    const dispatch = useDispatch()

    const handleClick = (type:string) => {
        dispatch(updateWidgetType(type))
    }

    return (
        <div className={`widget_filter-row ${type !== 'mobile' ? 'mb-20' : 'mb-4'}`}>
            <div className='widget_filter-container'>
                <div className="widget_filter-col">
                    <div onClick={() => handleClick('all')} className={`filter_item rounded-l-md ${type === 'all' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>all</div>
                    <div onClick={() => handleClick('web')} className={`filter_item ${type === 'web' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>web</div>
                    <div onClick={() => handleClick('mobile')} className={`filter_item rounded-r-md ${type === 'mobile' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>mobile</div>
                </div>
                {   
                    (type !== 'mobile') &&
                    <div className="page_type-col">
                        <div onClick={() => handleClick('page')} className={`filter_item-two rounded-l-md ${type === 'page' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>Page</div>
                        <div onClick={() => handleClick('product-section')} className={`filter_item-two ${type === 'product-section' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>Product Section</div>
                        <div onClick={() => handleClick('side-bar')} className={`filter_item-two ${type === 'side-bar' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>Side Bar</div>
                        <div onClick={() => handleClick('top-bar')} className={`filter_item-two rounded-r-md ${type === 'top-bar' ? 'shadow-lg scale-105 font-semibold' : 'shadow-inner'}`}>Top Bar</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default WidgetFilter