import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import DeleteIcon from '../../components/icons/DeleteIcon'
import DuplicateIcon from '../../components/icons/DuplicateIcon'
import EditIcon from '../../components/icons/EditIcon'
import ReactPaginate from 'react-paginate'
import CloseIcon from '../../components/icons/CloseIcon'

import '../HomePage/homepage.css';

import type { AppDispatch, RootState } from '../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { checkToken } from '../../App'
import Swal from 'sweetalert2'
import { CustomWidgetGet } from '../../types/widget';
import { getWidgetList } from '../../utils/requests';

export const showToastV2 = (message:string, icon: 'success' | 'error' = 'success') => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 2500
  });
  Toast.fire({
    icon: icon,
    title: message
  })
}

const WidgetBuilder:React.FC = () => {
    const permissions = useSelector((state: RootState) => state.authPermission.permissions);

    const [widgetData, setWidgetData] = useState<CustomWidgetGet>();

    useEffect(() => {
        getWidgetList().then(setWidgetData);
    }, []);

    const widgetItems = useMemo(() => !!widgetData && !!widgetData.data ? widgetData.data : [], [widgetData]);

    const handleDeleteWidget = (id: string) => {}

    return (
        <div className='homepage__container'>
        {/* {
            permissions.includes('create_page_layout') &&
            <div className="homepage__row--one">
            <form onSubmit={handleSumbit}>
                <input value={title} type="text" placeholder='page name...' onChange={handleOnChange}/>
                <div className='border-none shadow-lg w-full rounded-md overflow-hidden pr-2 bg-white  '>
                <select value={pageType} onChange={(e)=> setPageType(e.target.value)} name="types" id="types" className='p-3 outline-none w-full'>
                    <option value="page">Page</option>
                    <option value="campaign">Campaign</option>
                    <option value="campaign-landing">Campaign landing</option>
                    <option value="category">Category</option>
                    <option value="brand">Brand</option>
                    <option value="content">Content</option>
                </select>
                </div>
                <button className="btn" type='submit'>Add new</button>
            </form>
            </div>
        } */}
        <div className="homepage__row--two">
            <div className='w-full bg-white col-span-2 p-6 gap-6 rounded-md grid grid-cols-3'>
            <div className='col-span-2 relative'>
                <input type="text" placeholder='search page...'/>
                {/* {
                searchText.length > 0 &&
                <div onClick={removeSearchText} className='absolute right-2 top-1 z-10 bg-white rounded-full p-1 text-red-500 shadow cursor-pointer'>
                    <CloseIcon />
                </div>
                } */}
            </div>
            <button className='btn px-6'>Search</button>
            </div>
        </div>
        <div className="homepage__row--two">
            <ul>
            {widgetItems.map((widget)=>
                <li key={widget._id}>
                    <div className='grid grid-cols-2 w-full'>
                        <div>{widget.name}</div>
                        {/* <div>Type: <span className='text-sm font-light tracking-wide'>{widget.type}</span></div> */}
                    </div>
                    <div className='btns__wrapper'>
                        {/* <div className="duplicate__btn" onClick={() => handleCopyUrl(widget)}>
                        <DuplicateIcon />
                        </div> */}
                        {
                        permissions.includes('edit_page_layout') &&
                            <Link to={`/widgets/${widget._id}`} className='edit__btn'>
                                <EditIcon />
                            </Link>
                        }
                        {(widget.is_deletable && permissions.includes('delete_page_layout')) &&  <div className='delete__btn' onClick={() => {
                            handleDeleteWidget(widget._id)
                        }}>
                            <DeleteIcon />
                        </div>}
                    </div>
                </li>
            )}
            </ul>
        </div>
        {/* {
            showPagination &&
            <div>
            <ReactPaginate 
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={totalPage || 0}   
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}   
                onPageChange={handlePageClick}
                containerClassName={'flex items-center justify-center'}
                pageClassName={''}
                pageLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
                activeClassName={'bg-blue-500 text-white rounded-full shadow-lg'}
                previousClassName={''}
                previousLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
                breakClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
                nextClassName={''}
                nextLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
            />
            </div>
        } */}
        </div>
    )
}

export default WidgetBuilder