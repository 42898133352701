import { createSlice } from '@reduxjs/toolkit';

export interface RightImages {
    images: any[],
}

const initialState: RightImages = {
    images: [],
}

export const rightImagesSlice = createSlice({
    name: 'rightImages',
    initialState,
    reducers: {
        getRightImages: (state, action) => {
            const newArray = action.payload
            console.log('right images =>', newArray)
            state.images = [...newArray]
        }
    }
})

export const {getRightImages} = rightImagesSlice.actions

export default rightImagesSlice.reducer