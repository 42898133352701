import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction  } from "@reduxjs/toolkit"
import { CategoryItem } from '../types/category'

interface CategoryPayload {
    category: CategoryItem;
    breadcumb?: CategoryItem[];
    searchTexts?: string[];
}

interface StateType {
    categoryPopup: {
        active: boolean;
        category: CategoryItem | null;
        breadcumb?: CategoryItem[];
        searchTexts?: string[];
    };
    globalPopup: JSX.Element | null;
    loading: boolean;
}

const initialState:StateType = {
    categoryPopup: {
        active: false,
        category: null,
    },
    globalPopup: null,
    loading: false,
}

export const createFormDataSlice = createSlice({
    name: 'popupControl',
    initialState,
    reducers: {
        openCategoryPopup: (state) => {
            state.categoryPopup = {
                ...state.categoryPopup,
                active: true
            }
        },
        setCategoryPopup: (state, action: PayloadAction<CategoryPayload>) => {
            state.categoryPopup = {
                ...state.categoryPopup,
                category: action.payload.category,
                breadcumb: action.payload.breadcumb,
                searchTexts: action.payload.searchTexts
            }
        },
        clearCategoryPopup: (state) => {
            state.categoryPopup = {
                active: false,
                category: null,
                breadcumb: [],
                searchTexts: []
            };
        },
        setGlobalPopupElement: (state, action: PayloadAction<JSX.Element | null>) => {
            state.globalPopup = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
})

export const {
    setCategoryPopup,
    openCategoryPopup,
    clearCategoryPopup,
    setGlobalPopupElement,
    setLoading
} = createFormDataSlice.actions

export default createFormDataSlice.reducer