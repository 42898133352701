import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Permissions {
    permissions: string[],
}

const initialState: Permissions = {
    permissions: [
        'view_page_layout',
        'create_page_layout',
        'edit_page_layout',
        'delete_page_layout',
        'publish_page_layout',
        'view_media_manager',
        'create_media_manager',
        'delete_media_manager',
        'view_dynamic_menu',
        'create_dynamic_menu',
        'edit_dynamic_menu',
        'delete_dynamic_menu',
        'publish_dynamic_menu'
    ],
}

export const authPermissionsSlice = createSlice({
    name: 'authPermissions',
    initialState,
    reducers: {
        getAllPermissions: (state, action: PayloadAction<string[]>) => {
            // state.permissions = action.payload.filter((item: any) => {
            //     if(
            //         item === 'view_page_layout' ||
            //         item === 'create_page_layout' ||
            //         item === 'edit_page_layout' ||
            //         item === 'delete_page_layout' ||
            //         item === 'publish_page_layout' ||
            //         item === 'view_media_manager' ||
            //         item === 'create_media_manager' ||
            //         item === 'delete_media_manager' ||
            //         item === 'view_dynamic_menu' ||
            //         item === 'create_dynamic_menu' ||
            //         item === 'edit_dynamic_menu' ||
            //         item === 'delete_dynamic_menu' ||
            //         item === 'publish_dynamic_menu'
            //     ){
            //         return item
            //     }

            //     return false
            // })
        }
    }
})

export const {getAllPermissions} = authPermissionsSlice.actions

export default authPermissionsSlice.reducer