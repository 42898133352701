import React, { FC, useEffect, useState } from 'react'
import { Widget } from '../../../widgetList/widgetList'
import LinkForm, { LinkData } from '../LinkForm'

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

const FomrForTile:FC<Props> = ({choosenItem, onUpdate}) => {

    const [active, setActive] = useState(false);
    const [showTimer, setShowTimer] = useState(choosenItem.data?.showTimer ?? true);
    const [title, setTitle] = useState('')
    const [link, setLink] = useState<LinkData>({} as LinkData)

    useEffect(()=>{
        setTitle(choosenItem.title || '')
        setLink(choosenItem.link || {} as LinkData)
    },[choosenItem])

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const titleObj = {
            name: 'title',
            active: active,
            title: title,
            link: link,
            wType: choosenItem.wType,
            id: choosenItem.id,
            data: {
                showTimer: showTimer
            }
        }
        onUpdate(titleObj)
    }

    const handleLinkData = (data: LinkData) => {
        setLink(data)
    }
  return (
    <>
    { choosenItem.name === 'title' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title capitalize'>title</h1>
            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            <div>
                <div>
                    <div className='capitalize tracking-wide'>title :</div>
                    <input value={title} onChange={handleTitle} type="text" placeholder='type here....'/>
                </div>
                <div className='mt-3'>
                    <LinkForm currnetLinkData={link} onUpdate={handleLinkData}/>
                </div>

                <div className='checkbox'>
                    <div>Show Campaign Timer: </div>
                    <input type="checkbox" defaultChecked={showTimer} onChange={()=> setShowTimer(p => !p)}/>
                </div>
            </div>
            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FomrForTile