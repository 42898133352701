import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ShowSideBarState {
    value: boolean
}

const initialState: ShowSideBarState = {
    value: true
}

export const showSideBarSlice = createSlice({
    name: 'showSideBar',
    initialState,
    reducers: {
      toggleSideBar: (state) => {
        state.value = !state.value
      },
      openSideBar: (state) => {
        state.value = true
      },
      closeSideBar: (state) => {
        state.value = false
      }
    },
  })


export const { toggleSideBar, openSideBar, closeSideBar } = showSideBarSlice.actions

export default showSideBarSlice.reducer

