import { PageCategory, Widget, widgets } from "./../../widgetList/widgetList";
import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { _axios } from "../../api";
import { checkToken } from "../../App";
import Cookies from "js-cookie";
import { AnyIfEmpty } from "react-redux";
import { adminTokenCookieName } from "../../utils/constants";

export interface Page {
  _id: string;
  name: string;
  widgets: [];
  sidebar: [];
  topbar: [];
  status: string;
  title?: string;
  type: string;
  categories: PageCategory[];
  brands: PageCategory[];
  campaigns: PageCategory[];
  is_published_one?: boolean;
  active?: boolean;
  updatedAt?: string;
  change_log?: string;
  settings?: any;
}

export interface CreatePage {
  name: string;
  widgets: [];
  status: string;
}

export interface AllPagesSliceState {
  allPages: Page[];
  currentPage: number;
  perPage: number;
  totalPages: number;
  searchText: string;
  loading: boolean;
  addPageLoading: boolean;
  addPageError: any;
  error: any;
}

const initialState: AllPagesSliceState = {
  allPages: [],
  currentPage: 1,
  perPage: 10,
  totalPages: 0,
  searchText: '',
  loading: false,
  error: null,
  addPageLoading: false,
  addPageError: null
};

export const fetchAllPages = createAsyncThunk("allPages/addPages", async (one, {getState}) => {
  checkToken();
  const _getState = getState() as any;
  let currentPage = _getState?.allPages?.currentPage
  let perPage = _getState?.allPages?.perPage
  let searchText = _getState?.allPages?.searchText
  let url = ``
  if(searchText.length){
    url = `page/findall?currentPage=${currentPage}&perPage=${perPage}&search=${searchText}`
  }else {
    url = `page/findall?currentPage=${currentPage}&perPage=${perPage}`
  }
  const res = await _axios.get(
    process.env.REACT_APP_BUILDER_URL + url,
    {
      headers: {
        Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
      },
    }
  );
  return res.data;
});

export const fetchAllPagesV2 = createAsyncThunk("allPages/addPagesV2", async ({
  currentPage = 1,
  perPage = 10,
  searchText = ''
}: {
  currentPage?: number;
  perPage?: number;
  searchText?: string;
}) => {
  // checkToken();

  let url = ``
  if(searchText.length){
    url = `page/findall?currentPage=${currentPage}&perPage=${perPage}&search=${searchText}`
  }else {
    url = `page/findall?currentPage=${currentPage}&perPage=${perPage}`
  }
  const res = await _axios.get(
    process.env.REACT_APP_BUILDER_URL + url,
    {
      headers: {
        Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
      },
    }
  );
  return res.data;
});

export const addPageAsync = createAsyncThunk(
  "allPages/addPage",
  async (actionObj: any) => {
    checkToken();
    // const pData = {
    //   widgets: [],
    //   sidebar: [],
    //   topbar: [],
    //   type: actionObj.type,
    //   ...actionObj,
    //   status: "draft",
    //   name: actionObj.name,
    // };
    const res = await _axios.post(
      process.env.REACT_APP_BUILDER_URL + "page",
      actionObj,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
        },
      }
    );
    return res.data;
  }
);

export const removePageAsync = createAsyncThunk(
  "allPages/removePage",
  async (id: string) => {
    checkToken();
    const res = await _axios.delete(
      process.env.REACT_APP_BUILDER_URL + "page/" + id,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
        },
      }
    );
    return id;
  }
);

export const allPagesSlice = createSlice({
  name: "allPages",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPages.pending, (state) => {
        state.loading = true;
        state.allPages = [];
        state.error = "";
      })
      .addCase(fetchAllPages.fulfilled, (state, action) => {
        state.loading = false;
        state.allPages = action.payload.data;
        state.totalPages = action.payload.totalCount;
        state.error = "";
      })
      .addCase(fetchAllPages.rejected, (state, action) => {
        state.loading = false;
        state.allPages = [];
        state.error = action.error;
      })
      .addCase(fetchAllPagesV2.pending, (state) => {
        state.loading = true;
        state.allPages = [];
        state.error = "";
      })
      .addCase(fetchAllPagesV2.fulfilled, (state, action) => {
        state.loading = false;
        state.allPages = action.payload.data;
        state.totalPages = action.payload.totalCount;
        state.error = "";
      })
      .addCase(fetchAllPagesV2.rejected, (state, action) => {
        state.loading = false;
        state.allPages = [];
        state.error = action.error;
      })
      .addCase(addPageAsync.pending, (state) => {
        state.addPageLoading = true;
        state.addPageError = "";
      })
      .addCase(addPageAsync.fulfilled, (state, action) => {
        state.allPages.push(action.payload);
        state.addPageLoading = false;
        state.addPageError = "";
      })
      .addCase(addPageAsync.rejected, (state, action) => {
        state.addPageLoading = false; 
        state.addPageError = action.error;
      })
      .addCase(removePageAsync.fulfilled, (state, action) => {
        state.allPages = state.allPages.filter(
          (page) => page._id !== action.payload
        );
      });
  },
  reducers: {
    handlePageWidget: (
      state,
      action: PayloadAction<{ widget: Widget; pTitle: string }>
    ) => {
      const { widget, pTitle } = action.payload;
      const pages = JSON.parse(JSON.stringify(state.allPages));
      pages.map((page: any) => {
        console.log(page.widgets);
        if (page.name === pTitle) {
          return page.widgets.map((item: any) => {
            if (item.name === widget.name) {
              return widget;
            }
            return item;
          });
        }
        return page;
      });
    },
    handleAddPages: (state, action: PayloadAction<string>) => {},
    handleRemovePage: (state, action: PayloadAction<string>) => {},
    handleChnageCurrentPage: (state, action: PayloadAction<number>) => {
      const currentPageNumber = action.payload
      state.currentPage = currentPageNumber
    },
    handlePerPage: (state, action: PayloadAction<number>) => {
      const perPageNumber = action.payload
      state.perPage = perPageNumber
    },
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  },
});

export const { handleAddPages, handleRemovePage, handlePageWidget, handleChnageCurrentPage, handlePerPage, updateSearchText } =
  allPagesSlice.actions;

export default allPagesSlice.reducer;

function getState(): any {
  throw new Error("Function not implemented.");
}

