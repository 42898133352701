import { LogicalOrderTypes } from './../components/LogicBuilder/LogicalBuilder/index';
import { LinkData } from './../components/widgetFrom/LinkForm/index';

export interface Image {
    image: string,
    title: string,
    link: LinkData
}
export interface LogicalObj {
    id: number
    limit: string
    name?: string
    avatar?: string
}
export interface Logical {
    category: LogicalObj[],
    categoryOrder?: LogicalOrderTypes;
    brand: LogicalObj[],
    brandOrder?: LogicalOrderTypes;
    seller: LogicalObj[],
    sellerOrder?: LogicalOrderTypes;
    campaign: LogicalObj[]
    campaignOrder?: LogicalOrderTypes;
}
export interface PageCategory {
    id: number,
    name: string,
    slug: string,
}
export interface IndemandObj {
    image: string,
    title: string,
    subTitle: string,
    link: LinkData
}
export interface Widget {
    images?: string
    wType?: 'widgets' | 'sidebar' | 'topbar',
    name: string,
    id?: string,
    monarchmart_sku?: string,
    title?: string,
    link?: LinkData,
    active: boolean,
    data?: {
        images?: Image[],
        selectedCampaign?: PageCategory | null,
        showTimer?: boolean,
        setting?: {
            custom?: number[],
            logical?: Logical,
            productType?: ''
        }
        products?: [],
        featuredProduct?: {},
        shops?: [],
        bestSellingProducts?: {
            products: [],
            featuredImage: {
                image: '',
                title: '',
                url: LinkData,
                target: '',
            }
        },
        singleProductSlider?: {
            products: []
        },
        electronicsApplience?: {
            productTypes: [],
            featuredImage: {
                image: '',
                title: '',
                url: '',
                target: ''
            }
        },
        sellerProfile?: {
            campaignImages: [],
            coverImage: '',
            profileImage: ''
        },
        content?: '',
        rowLimit?: string,
        isSlider?: boolean,
        backgroundColor?: string,
        inDemandObj?: {
            inDemandObjOne?: {
                image: '',
                title: '',
                subTitle: '',
                link: {}
            }
            inDemandObjTwo?: {
                image: '',
                title: '',
                subTitle: '',
                link: {} 
            }
            inDemandObjThree?: {
                image: '',
                title: '',
                subTitle: '',
                link: {}
            }
            inDemandObjFour?: {
                image: '',
                title: '',
                subTitle: '',
                link: {}
            }
        },
        rightImages?: {
            rightImageOne?: {
                image: '',
                title: '',
                link: {}
            },
            rightImageTwo?: {
                image: '',
                title: '',
                link: {}
            },
            rightImageThree?: {
                image: '',
                title: '',
                link: {}
            }
        }
    },
    imageLimit?: number,
    featuredImage?: string,
    fetchuredImageUrl?: string | LinkData,
    fetcturedImagePosition?: string,
    fetcturedImagelinkTarget?: string,
    category?: any[],
    brandList?: any[],
    sellerList?: number[],
    productLimit?: string,
    rightImages?: Image[],
    rightImagesPosition?: string,
}

export const widgetNames = {
    lastVisitedProducts: 'last-visited-products',
    infiniteProductRow: 'infinite-product-row',
    smallWideBanner: 'small-wide-banner',
    banners: 'banners',
}

export const widgets: Widget[] = [
    {
        name: 'title',
        active: true,
        title: '',
        link: {} as LinkData
    },
    {
        name: 'slider',
        active: true,
        data: {},
    },
    {
        name: 'banner',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: '3-collum-images',
        active: true,
        data: {}
    },
    {
        name: 'populer-product',
        title: 'title here',
        active: true,
        imageLimit: 1,
        featuredImage: '',
        fetchuredImageUrl: '',
        fetcturedImagePosition: 'left',
        fetcturedImagelinkTarget: '',
        data: {}
    },
    {
        name: 'categories',
        active: true,
        category: []
    },
    {
        name: 'product-row',
        active: true,
        productLimit: ''
    },
    {
        name: 'one-collum-add',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'two-collum-add',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'brand-list',
        active: true,
        brandList: []
    },
    // {
    //     name: 'seller-list',
    //     active: true,
    //     sellerList: []
    // },
    {
        name: 'new-arrival',
        title: 'title here',
        active: true,
        featuredImage: '',
        fetchuredImageUrl: '',
        rightImages: [],
        rightImagesPosition: 'right',
        data: {}
    },
    {
        name: 'product-slider',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'todays-deal',
        title: '',
        active: true,
        data:{},
    },
    // {
    //     name: 'highlighted-shop',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'best-selling',
        title: '',
        active: true,
        data: {}
    },
    // {
    //     name: 'single-product',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    // {
    //     name: 'electronics-applience',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    // {
    //     name: 'seller-profile',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'content-editor',
        title: '',
        active: true,
        data: {}
    },
    {
        name: 'product-row-limit',
        title: '',
        active: true,
        data: {}
    },
    // {
    //     name: 'in-demand',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: widgetNames.lastVisitedProducts,
        title: 'Last Visited Products',
        active: true,
        data: {}
    },
    {
        name: widgetNames.smallWideBanner,
        title: 'Small & Wide Banner',
        active: true,
    },
    {
        name: widgetNames.banners,
        title: 'Banners',
        active: true,
    },
    // {
    //     name: widgetNames.infiniteProductRow,
    //     title: 'Infinite Product Row',
    //     active: true,
    //     data: {}
    // }
]

export const webWidgets: Widget[] = [
    {
        name: 'title',
        active: true,
        title: '',
        link: {} as LinkData
    },
    {
        name: 'slider',
        active: true,
        data: {},
    },
    {
        name: 'banner',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: '3-collum-images',
        active: true,
        data: {}
    },
    {
        name: 'populer-product',
        title: 'title here',
        active: true,
        imageLimit: 1,
        featuredImage: '',
        fetchuredImageUrl: '',
        fetcturedImagePosition: 'left',
        fetcturedImagelinkTarget: '',
        data: {}
    },
    {
        name: 'categories',
        active: true,
        category: []
    },
    {
        name: 'product-row',
        active: true,
        productLimit: ''
    },
    {
        name: 'one-collum-add',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'two-collum-add',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'brand-list',
        active: true,
        brandList: []
    },
    // {
    //     name: 'seller-list',
    //     active: true,
    //     sellerList: []
    // },
    {
        name: 'new-arrival',
        title: 'title here',
        active: true,
        featuredImage: '',
        fetchuredImageUrl: '',
        rightImages: [],
        rightImagesPosition: 'right',
        data: {}
    },
    {
        name: 'product-slider',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'todays-deal',
        title: '',
        active: true,
        data:{},
    },
    // {
    //     name: 'highlighted-shop',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'best-selling',
        title: '',
        active: true,
        data: {}
    },
    // {
    //     name: 'single-product',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'content-editor',
        title: '',
        active: true,
        data: {}
    },
    {
        name: 'product-row-limit',
        title: '',
        active: true,
        data: {}
    },
    {
        name: widgetNames.smallWideBanner,
        title: 'Small & Wide Banner',
        active: true,
    },
    {
        name: widgetNames.banners,
        title: 'Banners',
        active: true,
    },
    // {
    //     name: widgetNames.infiniteProductRow,
    //     title: 'Infinite Product Row',
    //     active: true,
    //     data: {}
    // }
]

export const mobileWidgets: Widget[] = [
    {
        name: 'title',
        active: true,
        title: '',
        link: {} as LinkData
    },
    {
        name: 'slider',
        active: true,
        data: {},
    },
    {
        name: 'banner',
        title: 'title here',
        active: true,
        data: {}
    },
    // {
    //     name: '3-collum-images',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'populer-product',
        title: 'title here',
        active: true,
        imageLimit: 1,
        featuredImage: '',
        fetchuredImageUrl: '',
        fetcturedImagePosition: 'left',
        fetcturedImagelinkTarget: '',
        data: {}
    },
    {
        name: 'categories',
        active: true,
        category: []
    },
    {
        name: 'product-row',
        active: true,
        productLimit: ''
    },
    {
        name: 'one-collum-add',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'two-collum-add',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'brand-list',
        active: true,
        brandList: []
    },
    // {
    //     name: 'seller-list',
    //     active: true,
    //     sellerList: []
    // },
    {
        name: 'new-arrival',
        title: 'title here',
        active: true,
        featuredImage: '',
        fetchuredImageUrl: '',
        rightImages: [],
        rightImagesPosition: 'right',
        data: {}
    },
    {
        name: 'product-slider',
        title: 'title here',
        active: true,
        data: {}
    },
    {
        name: 'todays-deal',
        title: '',
        active: true,
        data:{},
    },
    // {
    //     name: 'highlighted-shop',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'best-selling',
        title: '',
        active: true,
        data: {}
    },
    // {
    //     name: 'single-product',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    {
        name: 'product-row-limit',
        title: '',
        active: true,
        data: {}
    },
    // {
    //     name: 'in-demand',
    //     title: '',
    //     active: true,
    //     data: {}
    // },
    // {
    //     name: widgetNames.infiniteProductRow,
    //     title: 'Infinite Product Row',
    //     active: true,
    //     data: {}
    // }
]