import { useDispatch } from "react-redux"
import { setGlobalPopupElement } from "../features/popupSlice"
import CloseIcon from "./icons/CloseIcon"


export default function PopupWrapper({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setGlobalPopupElement(null))
  }

  return (
    <div className='fixed w-full h-full bg-black/10 inset-0 z-[1300] flex items-center justify-center'>
      <div className='absolute left-0 right-0 w-full h-full -z-10' onClick={handleClose}></div>
      <div className='relative bg-white rounded-md'>
        <button
          className='absolute top-0 right-0 bg-white rounded-full translate-x-1/2 -translate-y-1/2 p-1'
          onClick={handleClose}
        >
          <CloseIcon />
        </button>

        {children}
      </div>
    </div>
  )
}
