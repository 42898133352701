import React, {FC, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { closeImagesDetails } from '../../features/ImageDetails/imageDetails'
import CloseIcon from '../icons/CloseIcon'
import './imageDetails.css'

const ImageDetails:FC = () => {
    const image = useSelector((state: RootState) => state.imageDetails.image)

    const dispatch = useDispatch()

    const [showTost, setShowTost] = useState(false)

    useEffect(()=>{
        setTimeout(()=> {
            setShowTost(false)
        }, 5000)
    },[showTost])

    const handleSubmitImageDetails = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, path: any) => {
        e.preventDefault()
        navigator.clipboard.writeText(path)
        setShowTost(true)
    }

    const handleCloseImageDetails = () => {
        dispatch(closeImagesDetails())
    }

  return (
    <div className="image__details__popup--container">
        <div className="image__details__popup--row">
            <div className="image__details__popup--col">
                <div className='p-10 relative'>
                    {
                        image?.path ? 
                        <img className='w-[600px] h-[400px] aspect-video object-center object-contain' src={process.env.REACT_APP_IMAGE_URL + image?.path} alt="" />
                        :
                        <img className='' src="/img/image-placeholder.jpg" alt="" />
                    }
                    <div className='mt-6 flex items-center w-full justify-between'>
                        <div className='flex items-center gap-2 w-[75%]'>
                            <div className='text-sm uppercase font-semibold'>Image:</div>
                            <div className='text-sm font-light tracking-wide truncate'>{image?.path}</div>
                        </div>
                        <div>
                            <button onClick={(e) => handleSubmitImageDetails(e, image?.path)} className='bg-blue-500 text-white px-3 py-2 text-sm tracking-wide font-bold rounded-md shadow-lg hover:shadow-inner'>Copy Path</button>
                        </div>
                    </div>
                    <div onClick={handleCloseImageDetails} className='absolute -top-3 -right-3 bg-white cursor-pointer rounded-full text-red-500 p-1 shadow-lg'>
                        <CloseIcon />
                    </div>
                </div>
            </div>
            {
                showTost && 
                <div className='absolute top-5 right-5 bg-white rounded-md shadow-lg text-green-500 overflow-hidden'>
                    <div className='flex items-center gap-1 pr-4'>
                        <img className='w-12 h-12' src="/img/widgets/done.gif" alt="" />
                        <div className='text-green-5000 font-extrabold tracking-wide capitalize text-lg'>save</div>
                    </div>
                </div>
            }
        </div>
    </div>
  )
}

export default ImageDetails