import { getImageUrl } from "../../utils";
import { ImageSelectorV3 } from "../ImageSelector";
import DeleteIcon from "../icons/DeleteIcon";

export type InputTypes = 
'checkbox' |
'text' |
'number' |
'color' |
'password' |
'image' |
'select';

interface Props {
    label?: string;
    value?: any;
    type?: InputTypes;
    options?: {
        value: string;
        name: string;
    } [];
    placeholder?: string;
    onChange: (value: any) => void;
}

const FormInputItem = ({
    label,
    value,
    type = 'text',
    options,
    placeholder,
    onChange,
}: Props) => {

    console.log('value', value)

    switch (type) {
        
        case 'checkbox':
            const id = 'cb-' + Math.random().toString(36).substring(7);

            return (
                <div className="flex gap-2 items-center">
                    <input
                        id={id}
                        type='checkbox'
                        className='!w-auto'
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                    />
                    <label htmlFor={id} className='mainSliderForm__label'>{label}</label>
                </div>
            )
        case 'select':
            return (
                <div className='mainSliderForm__inputContainer'>
                    <label className='mainSliderForm__label'>{label}</label>
                    <div className='mainSliderForm__input'>
                        <select
                            className='mb-3 p-2 border border-gray-300 rounded-md w-full'
                            value={value}
                            onChange={e => onChange(e.target.value)}
                        >
                            {options?.map((item, index) => (
                                <option key={index} value={item.value}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            );
        case 'image':
            return (
                <div className='mainSliderForm__inputContainer mb-2'>
                    <label className='mainSliderForm__label'>{label}</label>
                    
                    {value && (
                        <div
                            className='relative w-full'
                        >
                            <img src={getImageUrl(value)} alt="" className='w-full max-h-[400px] border p-1 object-contain rounded-md mb-3' />
                            <button
                                className='absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1'
                                onClick={() => onChange('')}
                            >
                                <DeleteIcon />
                            </button>
                        </div>
                    )}

                    <div className='mainSliderForm__input'>
                        <ImageSelectorV3 
                            type='single'
                            onChange={onChange}
                        />
                    </div>
                </div>
            )
        case 'text':
        case 'number':
        case 'password':
        case 'color':
        default:
            return (
                <div className='mainSliderForm__inputContainer'>
                    <label className='mainSliderForm__label'>{label}</label>
                    <div className='mainSliderForm__input'>
                        {type === 'color' && (
                            <input 
                                type='text'
                                value={value}
                                className='mb-3'
                                onChange={e => onChange(e.target.value)}
                            />
                        )}
                        <input
                            type={type}
                            placeholder={placeholder}
                            value={value}
                            className='mb-3'
                            style={{
                                backgroundColor: type === 'color' ? value : undefined,
                            }}
                            onChange={e => onChange(e.target.value)}
                        />
                    </div>
                </div>
            )
    }
}

export default FormInputItem