import { adminTokenCookieName } from './../../utils/constants';
import { Logical } from './../../widgetList/widgetList'
import { LogicalObj } from './../../widgetList/widgetList'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction  } from "@reduxjs/toolkit"
import {_axios} from "../../api";
import { checkToken } from '../../App';
import Cookies from 'js-cookie';
import { LogicalOrderTypes } from '../../components/LogicBuilder/LogicalBuilder';

interface LogicBuilder {
    setting: {
        logical: Logical,
        custom: number[]
    },
    productForCustom: any,
    productForLogical: any,
    allProducts: any,
    loading: boolean,
    error: any
}

const initialState: LogicBuilder = {
    setting : {
        logical: {
            category: [],
            brand: [],
            seller: [],
            campaign: [],
            categoryOrder: 'desc',
            brandOrder: 'desc',
            sellerOrder: 'desc',
            campaignOrder: 'desc',
        },
        custom: []
    },
    productForCustom: [],
    productForLogical: [],
    allProducts:[],
    loading: false,
    error: null
}

export const fetchAllProducts = createAsyncThunk("logicBuilder/allProducts", async()=>{
    checkToken()
    const res = await _axios.get(process.env.REACT_APP_BUILDER_URL + 'catalog/product/page-builder/search', { headers: {
        "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
      }})
    console.log('res =>', res)
    return res.data.data
})

export const createLogicBuilderSlice = createSlice({
    name: 'logicBuilder',
    initialState,
    extraReducers: (builder) =>{
        builder
        .addCase(fetchAllProducts.pending, (state) => {
            state.loading = true
            state.allProducts = []
            state.error = ''
        })
        .addCase(fetchAllProducts.fulfilled, (state, action) => {
            state.loading = false
            state.allProducts = action.payload
            state.error = ''
        })
        .addCase(fetchAllProducts.rejected, (state, action) => {
            state.loading = false
            state.allProducts = []
            state.error = action.error
        })
    },
    reducers: {
        getChoosenProductsForCustom: (state, action: PayloadAction<any>) =>{
            state.productForCustom = action.payload
        },
        getChoosenProductsForLogical: (state, action: PayloadAction<any>) =>{
            state.productForLogical = action.payload
        },
        getCustomProducts: (state, action: PayloadAction<number[]>) => {
            state.setting.logical = {
                category: [],
                brand: [],
                seller: [],
                campaign: [],
                categoryOrder: 'desc',
                brandOrder: 'desc',
                sellerOrder: 'desc',
                campaignOrder: 'desc',
            };
            state.setting.custom = action.payload
        },
        getLogicalCategory: (state, action: PayloadAction<LogicalObj[]>) => {
            state.setting.custom = [];
            state.setting.logical.category = action.payload
        },
        getLogicalBrand: (state, action: PayloadAction<LogicalObj[]>) => {
            state.setting.custom = [];
            state.setting.logical.brand = action.payload
        },
        getLogicalSeller: (state, action: PayloadAction<LogicalObj[]>) => {
            state.setting.custom = [];
            state.setting.logical.seller = action.payload
        },
        getLogicalCampaign: (state, action: PayloadAction<LogicalObj[]>) => {
            state.setting.custom = [];
            state.setting.logical.campaign = action.payload
        },
        setLogialCategoryOrder: (state, action: PayloadAction<LogicalOrderTypes>) => {
            state.setting.logical.categoryOrder = action.payload;
        },
        setLogialBrandOrder: (state, action: PayloadAction<LogicalOrderTypes>) => {
            state.setting.logical.brandOrder = action.payload;
        },
        setLogialSellerOrder: (state, action: PayloadAction<LogicalOrderTypes>) => {
            state.setting.logical.sellerOrder = action.payload;
        },
        setLogialCampaignOrder: (state, action: PayloadAction<LogicalOrderTypes>) => {
            state.setting.logical.campaignOrder = action.payload;
        },
        clearSetting: (state) => {
            state.setting = {
                logical: {
                    category: [],
                    brand: [],
                    seller: [],
                    campaign: [],
                    categoryOrder: 'desc',
                    brandOrder: 'desc',
                    sellerOrder: 'desc',
                    campaignOrder: 'desc',
                },
                custom: []
            }
        },
    }
})

export const { 
    getCustomProducts, 
    clearSetting, 
    getLogicalCategory, 
    getLogicalBrand,  
    getLogicalSeller, 
    getLogicalCampaign, 
    getChoosenProductsForCustom, 
    getChoosenProductsForLogical,
    setLogialCategoryOrder,
    setLogialBrandOrder,
    setLogialSellerOrder,
    setLogialCampaignOrder
} = createLogicBuilderSlice.actions

export default createLogicBuilderSlice.reducer