import React, { FC } from 'react'
import './threeCollumImages.css'

const ThreeCollumsImages:FC = () => {
  return (
    <div className='threeCollumImages__container'>
        <div className="threeCollumImages__wrapper">
            <img src="/img/widgets/3-collum-banner.jpg" alt=''/>
        </div>
    </div>
  )
}

export default ThreeCollumsImages