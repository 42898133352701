import React, { useEffect } from 'react';
import Layout from './components/Layout';
import DashBoardPage from './pages/DashboardPage';
import HomePage from './pages/HomePage';
import PageDetails from './pages/PageDetails';
import MediaPage from './pages/MediaPage';
import MenuPage from './pages/MenuPage';
import MenuDetails from './pages/MenuDetails';
import {_axios} from './api';

import { Routes, Route, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { AxiosRequestHeaders } from 'axios';
import { useDispatch } from 'react-redux';
import { getAllPermissions } from './features/authPermissions/authPermissions';
import WidgetBuilder from './pages/WidgetBuilder';
import WidgetDetails from './pages/WidgetDetails';
import { adminTokenCookieName } from './utils/constants';

export const checkToken = () => {
  
  // Cookies.set('admin-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN1cGVyLXVzZXJAbW9uYXJjaG1hcnQuY29tIiwidHlwZSI6ImFkbWluIiwiaWF0IjoxNjcwMjk5NzAyLCJleHAiOjE3MDE4MzU3MDJ9.bxnsjkR_UKy1Ct3-RbH7hDrL0DpB0Orwz15dlj6Rqow', { domain: process.env.REACT_APP_COOKIE_URL });
  
  const token = Cookies.get(adminTokenCookieName)
    if(!token) {
       window.location.href = process.env.REACT_APP_LOGIN_REDIRECT as string
    }
    return token
}

export const removeToken = () => {
  // Cookies.remove('admin-token', { domain: process.env.REACT_APP_COOKIE_URL })
  // Cookies.remove(adminTokenCookieName, { domain: process.env.REACT_APP_COOKIE_URL }));
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const auth_token = checkToken()
    _axios.interceptors.request.use(
      config => {
        (config.headers as AxiosRequestHeaders)['Authorization'] = `Bearer ${auth_token}`;
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    const authData = async () => {
      await _axios.get(process.env.REACT_APP_AUTH_URL+'validate-token',{
        headers: {
          "Content-Type" : "application/json",
          "Authorization" : `Bearer ${auth_token}` 
        },
      }).then(res => {
        dispatch(getAllPermissions(res.data.permissions))
      }).catch(() => {
        removeToken();
        // window.location.href = process.env.REACT_APP_LOGIN_REDIRECT as string;
      })
    }
    authData()
  }, [location]);
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<DashBoardPage />} />
        <Route path="pages" element={<HomePage />} />
        <Route path="/widgets" element={<WidgetBuilder />} />
        <Route path="/widgets/:id" element={<WidgetDetails />} />
        <Route path="/pages/:pageTitle" element={<PageDetails />}/>
        <Route path="/media" element={<MediaPage />}/>
        <Route path="/menus" element={<MenuPage />}/>
        <Route path="/menus/:id" element={<MenuDetails />}/>

        <Route
          path="*"
          element={
            <div className='mt-52 text-4xl font-bold flex items-center justify-center'>
              <p>404! Page not found.</p>
            </div>
          }
        />
      </Routes>
    </Layout>
  );
}

export default App;
