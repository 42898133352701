import React from 'react'

const Campaign:React.FC = () => {
  return (
    <div className='campaign__container'>
        <div className="campaign__wrapper">
            <img src="/img/widgets/todays-deal.png" alt=''/>
        </div>
    </div>
  )
}

export default Campaign