import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface WidgetTypeState {
    type: string
}

const initialState: WidgetTypeState = {
    type: 'all'
}

export const WidgetTypeSlice = createSlice({
    name: 'WidgetType',
    initialState,
    reducers: {
      updateWidgetType: (state, action: PayloadAction<any>) => {
        state.type = action.payload
      },
    },
  })


export const { updateWidgetType } = WidgetTypeSlice.actions

export default WidgetTypeSlice.reducer