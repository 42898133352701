import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { setGlobalPopupElement } from '../../../features/popupSlice'
import { Image } from '../../../widgetList/widgetList'
import { Widget } from '../../../widgetList/widgetList'
import { ImageContainerEditButton } from '../../components'
import ImagePopUpV2 from '../../ImagePopUpV2'
import ImageSelector, { ImageSelectorV2 } from '../../ImageSelector'
import LinkForm, { LinkData } from '../LinkForm'
import SettingFormItems, { SettingItem, SettingItems } from '../../SettingFormItems'


interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

type Panel = 'main' | 'settings';
const panels: {
    name: Panel;
    title: string;
} [] = [
    {
        name: 'main',
        title: 'Main',
    },
    {
        name: 'settings',
        title: 'Settings',
    },
]

type SettingsNames = 
'width' |
'height';

const bannerSettings: SettingItem<SettingsNames> [] = [
    {
        name: 'Width',
        key: 'width',
        type: 'number',
    },
    {
        name: 'Height',
        key: 'height',
        type: 'number',
    },
]

const FormForBanner:FC<Props> = ({choosenItem, onUpdate}) => {
    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)
    console.log('choosenAllImages =', choosenAllImages)

    const [active, setActive] = useState<boolean>(choosenItem.active)
    const [chooseItemId, setChooseItemId] = useState(0)
    const [images, setImages] = useState<any[]>([])
    const [image, setImage] = useState<Image>({} as Image)
    const [title, setTitle] = useState('')
    const [link, setLink] = useState<LinkData>({} as LinkData)
    const [selectedImage, setSelectedImage] = useState('')

    const [panel, setPanel] = useState<Panel>('main');
    const [containerType, setContainerType] = useState<'full' | 'box'>((choosenItem as any)?.data?.settings?.containerType || 'box');
    const [settings, setSettings] = useState<SettingItems<SettingsNames>>((choosenItem as any)?.data?.settings as any);

    // useEffect(()=>{
    //     setImages([])
    // },[])

    // useEffect(()=>{
    //     setImages(choosenAllImages)
    // },[choosenAllImages])

    useEffect(()=>{
        const array = choosenItem.data?.images
        array?.map((item: any) =>{
                setSelectedImage(item.image)
                setTitle(item.title)
                setLink(item.link) 
        })
    }, [choosenItem])

    const dispatch = useDispatch()

    const handleClick = (item: any) => {
        setChooseItemId(item.id)
        setSelectedImage(item.path)
    }
    
    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const newImageObj = {...image}
        newImageObj.image = selectedImage
        newImageObj.title = title
        newImageObj.link = link
        setImage(newImageObj)
        const widgetObj = {
            name: 'banner',
            active: active,
            data : {
                images: [newImageObj],
                settings: {
                    ...settings,
                    containerType: containerType,
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(widgetObj)
    }

    const handleLinkData = (data: LinkData) => {
        setLink(data)
    }

    const handleImageSelector = (index?: number) => {
        dispatch(setGlobalPopupElement(
            <ImagePopUpV2
                single={true}
                onUpdate={images => {
                    const arrayImages = images.map((item: any) => {
                        return(
                                {
                                    image: item.path,
                                    title: item.title,
                                    link: {} as LinkData
                                }
                        )
                    })

                    // setImages(arrayImages)
                    const item = arrayImages[0];

                    setSelectedImage(item.image)

                    dispatch(setGlobalPopupElement(null));
                }}
                onClose={() => {
                    dispatch(setGlobalPopupElement(null));
                }}
            />
        ))
    }

    return (
    <>
    { choosenItem.name === 'banner' ?
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <h1 className='title'>Banner</h1>

            {/* panels switch */}
            <div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
                {panels.map((p) => (
                    <button
                        key={p.name}
                        type="button"
                        className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
                            panel === p.name
                                ? 'border-green-500 text-black'
                                : 'text-black/50 hover:text-black/70'
                        }`}
                        onClick={() => setPanel(p.name)}
                    >
                        {p.title}
                    </button>
                ))}
            </div>

            {/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
            {panel === 'main' ? (
                <div>
                    <div className='capitalize'>Banner image: </div>
                    <div className="grid grid-cols-3 gap-2">
                        {
                            choosenAllImages.map((image: any)=>{
                                return (
                                    <div key={image?.id} className={`p-1 rounded-md ${chooseItemId === image?.id && 'bg-blue-300'}`}>
                                        <img onClick={()=>handleClick(image)} className='w-full h-12 aspect-auto object-cover object-center rounded-md cursor-pointer' src={process.env.REACT_APP_IMAGE_URL+image?.path} alt="" />
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        images.length > 0 && <div className='text-xs font-light py-2'>Choose feature image from images...</div> 
                    }
                    {
                        selectedImage.length > 0 ? 
                        (
                            <ImageContainerEditButton
                                index={0}
                                imageUrl={process.env.REACT_APP_IMAGE_URL + selectedImage}
                                handleImageSelector={handleImageSelector}
                            />
                        ) :
                        // <img className='rounded-md mb-1 mt-2' src={process.env.REACT_APP_IMAGE_URL + selectedImage} alt="" /> :
                        <img className='rounded-md mb-1 mt-2' src='/img/image-placeholder.jpg' alt="" />
                    }
                    <input className='mb-1' value={title} type="text" placeholder='title :' onChange={(e) => handleTitle(e)}/>
                    <LinkForm currnetLinkData={link} onUpdate={handleLinkData}/>

                    <ImageSelectorV2 onClick={handleImageSelector} />
                </div>
            ): panel === 'settings' ? (
                    <div>
                        {/* image container full or box radio setting */}
                        <div className='flex flex-col mb-2'>
                            <div>Image container: </div>
                            <div className='[&>input]:!w-4 [&>input]:mx-2'>
                                <input type="radio" id="b-type-full" name="imageContainer" value="full" 
                                    onChange={(e) => setContainerType(e.target.value as 'full' | 'box')}
                                    checked={containerType === 'full'}
                                />
                                <label htmlFor="b-type-full">Full</label>

                                <input type="radio" id="b-type-box" name="imageContainer" value="box" 
                                    onChange={(e) => setContainerType(e.target.value as 'full' | 'box')}
                                    checked={containerType === 'box'}
                                />
                                <label htmlFor="b-type-box">Box</label>
                            </div>
                        </div>

                        <SettingFormItems
                            settings={settings}
                            settingItems={bannerSettings}
                            onChange={(key, value) => {
                                setSettings(prev => ({
                                    ...prev,
                                    [key]: value,
                                }))
                            }}
                        />
                    </div>
            ): null}
            {/* <ImageSelector/> */}

            <div>
                <button className='mainSliderForm__btn'>save</button>
            </div>
        </form>
        : <div className='flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52'>Please check widget name!</div>
    }
    </>
  )
}

export default FormForBanner