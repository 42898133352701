import React, { FC } from 'react'
import CloseIcon from '../../icons/CloseIcon'
import './alert.css'

interface Props {
    title: string,
    setTimeout: React.Dispatch<React.SetStateAction<any>>
}

const Alert:FC<Props> = ({title, setTimeout}) => {
  return (
    <div className="alert__container">
        <div className="relative">
            <h1>{title}</h1>
            {/* <div className="alert__close--btn" onClick={() => setTimeout(false)}>
                <CloseIcon/>
            </div> */}
        </div>
    </div>
  )
}

export default Alert