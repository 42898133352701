import React from 'react'
import SideBarItem from './SidebarItem'

import './sidebar.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
import { envs, logos } from '../../../utils/constants';

interface Items {
  id: string;
  title: string;
  icon: string;
  url: string
}

const SideBar:React.FC = () => {
  const permissions = useSelector((state: RootState) => state.authPermission.permissions)
  const showSideBar = useSelector((state: RootState) => state.showSideBar.value)

  const items:Items[] = [
    {
      id: '1',
      title: 'home',
      icon: 'one',
      url: ''
    },
    { 
      id: '2',
      title: 'widgets',
      icon: 'two',
      url: 'widgets'
    },
    {
      id: '3',
      title: 'page layout',
      icon: 'three',
      url: 'pages'
    },
    {
      id: '4',
      title: 'media',
      icon: 'three',
      url: 'media'
    },
    {
      id: '5',
      title: 'menus',
      icon: 'four',
      url: 'menus'
    }
  ]
  return (
    <div className='sidebar__container'>
      <div className='logo__wrapper'>
        <div className='logo'>
          <a href={envs.adminUrl}>
            {
              showSideBar ? 
              <img className='w-full h-24 aspect-auto p-6' src={logos.fullLogoBlack} alt="logo" />
              : <img className='w-full h-24 aspect-auto p-3' src={logos.iconOng} alt="logo" />
            }
          </a>
        </div>
      </div>
      <div className="sidebar__row">
        <div className="sidebar__item--wrapper">
          {
            items.map(item =>{
              return(
                (
                  (item.title === 'page layout' && permissions.includes('view_page_layout')) || 
                  (item.title === 'media' && permissions.includes('view_media_manager')) ||
                  (item.title === 'menus' && permissions.includes('view_dynamic_menu')) ||
                  (item.title === 'widgets' && permissions.includes('view_dynamic_menu')) ||
                  (item.title === 'home')
                ) ?
                <SideBarItem  title={item.title} icon={item.icon} url={item.url} key={item.id}/> :
                null
              ) 
            })
          }
        </div>
      </div>
    </div>
  )
}

export default SideBar