import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { Widget } from '../../../widgetList/widgetList'
import CloseIcon from '../../icons/CloseIcon'
import ImageSelector from '../../ImageSelector'
import LinkForm, { LinkData } from '../LinkForm'

interface Props {
    choosenItem: Widget
    onUpdate: (w: Widget) => void
}

interface PromotionalImages {
    id: number,
    image: string,
    link: LinkData,
    target: string
}

const FormForSellerProfile:FC<Props> = ({choosenItem, onUpdate}) => {
    const choosenAllImages = useSelector((state: RootState) => state.showImagePopUp.images)

    const showImagePopUpType = useSelector((state: RootState) => state.showImagePopUp.type)
    const [coverImage, setCoverImage] =  useState('')
    const [profileImage, setProfileImage] = useState('')
    const [campaignImages, setCampaignImages] = useState<PromotionalImages []>([])

    useEffect(()=>{
        const arrayImages = choosenAllImages.map((item: any) => {
            return(
                    {
                        id: item.id,
                        image: item.path,
                        link: item.link,
                        target: item.target
                    }
            )
        })

        setCampaignImages((pImages)=> {
            return pImages.concat(arrayImages)
        })

    },[choosenAllImages])

    useEffect(()=>{
        setCampaignImages(choosenItem?.data?.sellerProfile?.campaignImages || [])
        setProfileImage(choosenItem?.data?.sellerProfile?.profileImage || '')
        setCoverImage(choosenItem?.data?.sellerProfile?.coverImage || '')
    },[choosenItem])

    useEffect(()=>{
        if(showImagePopUpType === 'cover' || showImagePopUpType === 'profile'){
            choosenAllImages.map((item: any) =>{
                if(showImagePopUpType === 'cover'){
                    return (
                        setCoverImage(item.path)
                    )
                }
                if(showImagePopUpType === 'profile') {
                    return (
                        setProfileImage(item.path)
                    )
                }
            })
        }else {
            setCampaignImages(choosenAllImages)
        }
    },[choosenAllImages])

    const handleChangeCoverImage = () => {

    }

    const handleTarget = (image: PromotionalImages) => {
        setCampaignImages((pImages)=>{
            return pImages.map((item)=>{
                const copyItem = {...item}
                if(item.id === image?.id){
                    copyItem.target = 'blank'
                }
                return copyItem
            })
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault()
        const widgetObj: any = {
            name: 'seller-profile',
            active: true,
            data : {
                sellerProfile: {
                    campaignImages: campaignImages,
                    coverImage: coverImage,
                    profileImage: profileImage
                }
            },
            wType: choosenItem.wType,
            id: choosenItem.id
        }
        onUpdate(widgetObj)
    }

    const removeImagesFromLOaclState = (index: any) =>{
        const filterImages = campaignImages.filter((_,i) => i !== index)
        setCampaignImages(filterImages)
    }

    const handleRemoveImage = (index: number) => {
        removeImagesFromLOaclState(index)
        // const payloadObj = {
        //     index,
        //     id: choosenItem.id,
        //     pageTitle
        // }
        // dispatch(removeImage(payloadObj))
    }

    const handleLinkData = (data: LinkData, image?: any) => {
        setCampaignImages((pImages)=>{
            return pImages.map((item)=>{
                const copyItem = {...item}
                if(item.id === image?.id){
                    copyItem.link = data
                }
                return copyItem
            })
        })
    }


    return (
        <form onSubmit={handleSubmit} className='mainSliderForm__container'>
            <div>
                <h1 className='text-center capitalize pb-3 border-b-2'>seller profile</h1>
            </div>
            <div className='mt-3'>
                <p className='text-xs font-light capitalize'>cover image:</p>
                {
                    coverImage.length > 0 ? 
                    <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + coverImage} alt="" /> :
                    <img onClick={handleChangeCoverImage} className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                }
                <div className='mt-4'>
                    <ImageSelector type="cover"/>
                </div>
            </div>
            <div className='mt-6'>
                <p className='text-xs font-light capitalize border-b pb-2'>profile image:</p>
                {
                    profileImage.length > 0 ?
                    <img className='rounded-md mt-2' src={process.env.REACT_APP_IMAGE_URL + profileImage} alt="" /> :
                    <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />

                }
                <div className='mt-4'>
                    <ImageSelector type="profile"/>
                </div>
            </div>
            <div className='mt-6'>
                <p className='text-xs font-light capitalize border-b pb-2'>campaign images:</p>
                {
                    campaignImages?.length > 0 ?
                    <div className='grid grid-cols-1 gap-4'>
                        {
                            campaignImages?.map((item: any, index: number) =>{
                                return(
                                    <div key={item.name} className='relative mb-4 last:mb-0 mt-2 shadow-lg p-2 bg-white rounded-md'>
                                    <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+ item.path} alt="" />
                                    <LinkForm currnetLinkData={item?.link} onUpdate={() => handleLinkData} selectedImage={item}/>
                                    <div onClick={() => handleTarget(item)} className='flex items-center gap-4 mt-2 cursor-pointer'>
                                        <div className='text-xs font-light capitalize'>target blank: 
                                        </div>
                                        <div className='w-5 h-5 bg-gray-300 rounded-md shadow-inner'></div>
                                    </div>
                                    <div className='image__popup--close-btn' onClick={()=>handleRemoveImage(index)}>
                                        <CloseIcon />
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className='grid grid-cols-2 gap-4'>
                        <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                        <img className='rounded-md mt-2 cursor-pointer' src="/img/image-placeholder.jpg" alt="" />
                    </div>
                }
                <div className='mt-4'>
                    <ImageSelector type="normal"/>
                </div>
            </div>
            <div>
                <button className='text-center tracking-wide text-white bg-green-400 py-3 cursor-pointer mt-4 w-full'>save</button>
            </div>
        </form>
    )
}

export default FormForSellerProfile