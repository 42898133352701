import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction  } from "@reduxjs/toolkit"

export interface FormDataState {
    formData: Object;
    widgetChangeLogs: string [];
}

const initialState:FormDataState = {
    formData: {},
    widgetChangeLogs: []
}

export const createFormDataSlice = createSlice({
    name: 'formData',
    initialState,
    reducers: {
        handleFormData: (state, action: PayloadAction<Object>) => {
            const newObj = action.payload
            state.formData = {...newObj}
        },
        setWidgetChangeLogs: (state, action: PayloadAction<string>) => {
            const changeLog = action.payload
            state.widgetChangeLogs.push(changeLog)
        },
        clearWidgetChangeLogs: (state) => {
            state.widgetChangeLogs = []
        },
    }
})

export const { 
    handleFormData, 
    setWidgetChangeLogs,
    clearWidgetChangeLogs
} = createFormDataSlice.actions

export default createFormDataSlice.reducer